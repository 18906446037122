import './StudentPayment.css';
import { useState, useEffect } from 'react';
import {useStripe, useElements, CardElement, PaymentElement} from '@stripe/react-stripe-js';
import { auth, levelUp } from '../../../Firebase'
import {site, port } from '../../EnvVariables'

function StudentPaymentIntent(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState(null)
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }
        
        //get the last four digits, card brand, and expiration date
        const cardElement = elements.getElement(CardElement);
        
        setIsProcessing(true)
        stripe.confirmSetup({
            elements,
            redirect: "if_required"
        }).then((result) => {
            if (result.error) {
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc)
                console.log(result.error.message);
                setPaymentMessage(result.error.message)
                setIsProcessing(false)
            } else {
                // The setup has succeeded. Display a success message.
                //console.log(result);
                //setPaymentMessage("Payment Successful")
                setIsProcessing(false)
                createCustomer(result)
                levelUp(props.uid, 2)
                //this is where we switch to the payment added screen
                props.triggerPaymentPage()
            }
        });
        

        const createCustomer = (data) => {
                setIsProcessing(false);
                const uid = props.uid
                const userEmail = auth.currentUser.email;
                const name = auth.currentUser.displayName;
                data = {...data, userEmail, uid, name}
                const url = `${site}${port}/api/v1/payment-confirmation`;
                fetch (url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }).then((res) => res.json())
                .then((data) => {
                    //console.log(data)
                    //get the customer_id, payment_method_id
                    props.triggerPaymentPage(data.status, data.card)
                })
        }
       
        
        
    };

    return (
        <div className="payment-container-parent">   
        <form className={"payment-container"}>
            <PaymentElement className="card-element"/>
            <button onClick={handleSubmit} disabled={isProcessing} id="submit">
                    {isProcessing ? "Processing..." : "Add Payment Method"}
            </button>
            {paymentMessage && 
                <div className="payment-message">
                    {paymentMessage}
                </div>}
        </form> 

        
        </div>
    )
}

export default StudentPaymentIntent