import './SignInPopup.css'
import {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import Rocket3 from './Rocket3'
import googleIcon from '../img/googleicon.png'
import { signInWithGoogle } from '../Firebase'
import { useNavigate } from "react-router-dom";


function SignInPopup(props) {

    const handleGoogleSignIn = async () => {
        try {
            let response = await signInWithGoogle();
            navigate("/app");
            
        } catch (error) {
            alert(error);
            
        }
    }
    const [signState, setSignState] = useState(props.type);
    const navigate = useNavigate();

    
    return (
        <div className="card-popup-wrapper">
            <div className="card-popup-container-header-box">
                <div className="card-popup-container-header" onClick={props.onExit}>
                    <FontAwesomeIcon icon={faArrowLeftLong}/>
                    <p>Back</p>
                </div>
            </div>
            
            {signState === "sign-in" ? (

            <div className="sign-popup-container">
                
                <div className="sign-in-container">
                    <h1>Welcome Back!</h1>
                    <form>
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"/>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" placeholder="Password"/>
                    </form>
                    <a>Forgot your password?</a>
                    <button>Sign In</button>
                    <div className="google-signin" onClick={handleGoogleSignIn}>
                            <img src={googleIcon} alt="google icon"/>
                            <p>Sign in with Google</p>
                    </div>

                    <div id="signin-button"></div>
                    <h4>Don't have an account?</h4>


                </div>
                <div className="sign-up-side">
                    <section>
                        <h1>New to Ascend?</h1>
                        <Rocket3></Rocket3>
                        <h2>Sign up with Google or fill in your info. Let's get you onboard.</h2>
                        <button>Sign Up</button>
                    </section>
                </div>
            </div>

            ) : (
            <div className="sign-popup-container">
                <div className="sign-up-container">
                    <h1>Get onboard!</h1>
                        <div className="google-signin" onClick={handleGoogleSignIn}>
                            <img src={googleIcon} alt="google icon"/>
                            <p>Sign up with Google</p>
                        </div>
                </div>
                <div className="sign-in-side">
                    <section>
                        <h1>Get ready for launch!</h1>
                        <h2>Create an account to get started.</h2>
                        <p>Already have an account?</p>
                    </section>
                </div>
            </div>
                )}
                
        </div>
    )
}

export default SignInPopup