import { Navigate } from "react-router-dom";
import { auth } from "../Firebase";
import { useState, useEffect } from "react";
import {onAuthStateChanged } from "firebase/auth";

export default function ProtectedRoute({ children }) {
    const [authState, setAuthState] = useState({
        isSignedIn: false,
        pending: true,
        user: null,
      })

    useEffect(() => {  
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthState({
                    isSignedIn: true,
                    pending: false,
                    user: user,
                })
            } else {
                setAuthState({
                    isSignedIn: false,
                    pending: false,
                    user: null,
                })
            }
        }
        )
    })

    if (authState.pending) {
        return true
    }
    if (!authState.isSignedIn) {
        return <Navigate to="/" />
    }
    return children;

}
//

