import './Invited.css';
import { useState, useEffect } from 'react';


function Invited(props) {

    return (
        <div className="invited-container">
            <h1>{props.inviteEmail} has invited you to Ascend!</h1>
            <p>Let's start learning!</p>
            <div className="invite-buttons">
                <button className="invite-accept" onClick={() => props.continue()}>Accept</button>
                <button className="invite-decline" onClick={() => props.no()}>Decline</button>
            </div>
        </div>
    )
}

export default Invited