import './ScheduleSideDrawer.css';
import { useState, useEffect } from "react";
import TimePicker from 'react-time-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {auth, db, getStudents, levelUp} from '../../Firebase';
import { doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore"; 
import Loader from './Loader';
import { site, port } from '../EnvVariables';

import { datadogLogs } from '@datadog/browser-logs';


function ScheduleSideDrawer(props) {
    console.log(props)
    const user = auth.currentUser;
    const firstName= user.displayName.split(' ')[0];
    const [studentName, setStudentName] = useState(props.name);
    const [studentUid, setStudentUid] = useState(props.studentUid);
    const [dateValue, setDateValue] = useState(props.date);
    const [value, onValue] = useState(props.startTime);
    const [hour, setHour] = useState(props.durationH? props.durationH : 0);
    const [minute, setMinute] = useState(props.durationM? props.durationM : 0);
    const [textAreaValue, setTextAreaValue] = useState(props.notes);
    const [editTime, setEditTime] = useState(false);
    const [editHour, setEditHour] = useState(false);
    const [editMinute, setEditMinute] = useState(false);
    const [editNotes, setEditNotes] = useState(false);
    const [loader, setLoader] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);

    useEffect(() => {
        const students = getStudents(user.uid)
        students.then((data) => {
            console.log(data)
            setStudentList(data)
        })
    }, [])
    const handleExit = (value) => {
        console.log(value)
        console.log('exit');
        props.onClickExit(false)
    }
    const handleSelectStudent = (e) => {
        //get the key of the student
        //get the data-uid attribute of the option selected
        const key = e.target.options[e.target.selectedIndex].getAttribute('data-uid');
        setStudentUid(key);
        setStudentName(e.target.value);
    }

    const convertDate = (date) => {
        console.log(date)
        //convert 2022-12-01 to December 1, 2022
        const dateArray = date.split('-');
        const arrayOfMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const month = parseInt(dateArray[1]) - 1;
        const day = dateArray[2];
        const year = dateArray[0];
        return `${arrayOfMonths[month]} ${day}, ${year}`;
    }

    const convertTime = (time) => {
        console.log(value)
        console.log(time)
        const timeArray = time.split(':');
        const hour = parseInt(timeArray[0]);
        const minute = timeArray[1];
        if (hour > 12){
            return `${hour - 12}:${minute} PM`
        } else if (hour === 12){
            return `${hour}:${minute} PM`
        } else if (hour === 0){
            return `12:${minute} AM`
        } else {
            return `${hour}:${minute} AM`
        }
    }

    const sendCalendarRequest = (formData) => {
        setLoader(true)

        const uid = auth.currentUser.uid;
        console.log(formData)

        //split time by :
        const timeArray = formData.time.split(':');
        var hour = parseInt(timeArray[0]);
        var minute = parseInt(timeArray[1]);
        hour = hour + formData.hour;
        minute = minute + formData.minute;
        if (minute === 60){
            hour = hour + 1;
            minute = 0;
        }
        if (minute > 60){
            hour = hour + 1;
            minute = minute - 60;
        }
        if (hour > 24){
            hour = hour - 24;
        }
        //convert minutes to string and if it is length 1, add a 0 to the front
        if (minute.toString().length === 1){
            minute = `0${minute}`
        }

        formData.endTimehm = `${hour}:${minute}`

        setDoc(doc(db, "events", formData.eventId), formData).then(() => {
            levelUp(uid, 1)
            setLoader(false)
            props.onClickExit(false)
            props.newSuccessfulRequest()
            datadogLogs.logger.info(formData)

        }).catch((error) => {
            console.error('Error adding document: ', error);
            datadogLogs.logger.error(formData)
        })}

    //make a function that get the seconds from epoch basd on date (2023-02-24) and time ("14:00")
    const getSecondsFromEpoch = (date, time) => {
        const dateArray = date.split('-');
        const year = parseInt(dateArray[0]);
        const month = parseInt(dateArray[1]) - 1;
        const day = parseInt(dateArray[2]);
        const timeArray = time.split(':');
        const hour = parseInt(timeArray[0]);
        const minute = parseInt(timeArray[1]);
        const seconds = 0;
        const dateObject = new Date(year, month, day, hour, minute, seconds);
        const secondsFromEpoch = dateObject.getTime() / 1000;
        console.log(secondsFromEpoch)
        return secondsFromEpoch;
    }

    const grabFormData = () => {
        //get the current time zone like PST
        console.log('grab form data')
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (hour === 0 && minute === 0){
            alert('Please enter a duration greater than 0 minutes.')
            return
        }

        const formData = {
            utcTime: getSecondsFromEpoch(props.date, value),
            date: dateValue,
            titleDate: (props.date),
            time: value,
            additionalInfo: textAreaValue,
            timeZone: timeZone,
            hour: hour,
            minute: minute,
            uid: studentUid,
            studentName: studentName,
            requester: "student",
            requestee: "tutor",
            agreement: false,
            type: "student-requested",
            allDay: true, //if false, shows time snippet
            classNames: ['student-request'],
            status: 'pending',
            zoomLink: false,
            zoomPassword: false,
            assignedTutor: false,
            eventId: makeid(16),
            notes: '',
            attendedNotes: '',
            title: "Student Requested",
            
        }
        if (textAreaValue !== '') {
            setTextAreaValue('');
        }

        if (props.create) {
            formData.type = 'tutor-created';
            formData.title = 'Tutoring Session';
            formData.classNames = ['request-confirmed'];
            formData.status = 'confirmed';
            formData.uid = studentUid;
            formData.studentName = studentName;
            console.log(formData)
            fetch(`${site}${port}/api/v1/acceptEvent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data:formData})
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                
                if (data.status === false){
                    alert('There was an error sending your request. Please try again.')
                    datadogLogs.logger.error(data);
                    return
                }
                else {
                    formData.assignedTutor = firstName;
                    formData.zoomLink = data.zoomLink;
                    formData.zoomPassword = data.zoomPassword;
                    sendCalendarRequest(formData);
                    datadogLogs.logger.info(data);
                }
            })
        }
        sendCalendarRequest(formData);
        //alert('Your request has been submitted. We will get back to you as soon as possible.')
    }
    const handleChange = (event) => {
        setTextAreaValue(event.target.value);
    }
    const handleTimeChange = (event, type) => {
        if (type === 'hour') {
            setHour(parseInt(event.target.value));
        } else {
            setMinute(parseInt(event.target.value));
        }
    }

    const handleRescheduleSubmit = () => {
        if (hour === 0 && minute === 0){
            alert('Please enter a duration greater than 0 minutes.')
            return
        }
        setLoader(true)
        const uid = auth.currentUser.uid;
        //make an api call

        updateDoc(doc(db, "events", props.eventId), {
            time: value,
            hour: hour,
            minute: minute,
            status: 'pending',
            additionalInfo: textAreaValue,
            endTimehm: endTime24(value, hour, minute),
            classNames: ['student-request'],
            title: "Student Requested"
        }).then(() => {
            console.log('Document successfully updated!');
            setLoader(false)
            props.onClickExit(false)
            props.newSuccessfulRequest()
        }).catch((error) => {
            // The document probably doesn't exist.
            console.error('Error updating document: ', error);
        });
    }

    const handleDeleteEvent = () => {
        setLoader(true)
        //make an api call
        const response = deleteDoc(doc(db, auth.currentUser.uid, props.eventId)).then(() => {
            console.log('Document successfully deleted!');
            setLoader(false)
            props.onClickExit(false)
            props.deleteEventFromList(props.eventId)
            datadogLogs.logger.info(props)
        }).catch((error) => {
            console.error('Error removing document: ', error);
            datadogLogs.logger.erro(props)
        });
    }
    

    const endTime = (startTime, hour, minute) => {
        const startHour = parseInt(startTime.split(':')[0]);
        const startMinute = parseInt(startTime.split(':')[1]);
        const endMinute = startMinute + minute;
        const endHour = startHour + hour;
        if (endMinute > 60){
                return convertTime(`${endHour + 1}:${fillSingleDigit(endMinute) - 60}`)
            } else {
                return convertTime(`${endHour}:${fillSingleDigit(endMinute)}`)
            }
        }
    
    const endTime24 = (startTime, hour, minute) => {
        const startHour = parseInt(startTime.split(':')[0]);
        const startMinute = parseInt(startTime.split(':')[1]);
        const endMinute = startMinute + minute;
        const endHour = startHour + hour;
        if (endMinute > 60){
                return `${endHour + 1}:${fillSingleDigit(endMinute) - 60}`
            } else {
                return `${endHour}:${fillSingleDigit(endMinute)}`
            }
        }

    const fillSingleDigit = (value) => {
        if (value < 10){
            return `0${value}`
        } else {
            return value
        } 
    }





    return (
        <div className="schedule-side-drawer-wrapper">
                    <div className="schedule-side-drawer-exit-wrapper" onClick={handleExit}></div>
                    <div className="schedule-side-drawer-container">

                        {
                            loader ? 
                                <div className="loader-center">
                                    <Loader></Loader>
                                </div> : (
                                <>
                                    <div className="schedule-side-drawer-header">
                                        <FontAwesomeIcon icon={faArrowLeft} onClick={handleExit}></FontAwesomeIcon>
                                        {
                                            props.event && !deleteConfirm && (
                                                <button onClick={() => setDeleteConfirm(true)}>Delete</button>
                                            )
                                        } 

                                    </div>
                                    {
                                        deleteConfirm && (
                                            <div className="deletebox">
                                                <h3>Are you sure you want to delete this request?</h3>
                                                <div>
                                                <button className="no" onClick={() => setDeleteConfirm(false)}>No</button>
                                                    <button className="yes" onClick={() => handleDeleteEvent()}>Yes</button>
                                                    
                                                </div>
                                            </div>
                                            
                                        )
                                    }
                                    {
                                        !deleteConfirm && (
                                        <>
                                            <h4>{convertDate(dateValue)}</h4>
                                            
                                            { //for non events
                                                !props.event && ( 
                                                <>
                                            
                                                {
                                                    (props.firstConsult) && (
                                                    <p>Need help for an uncoming exam or project? Feel free to schedule a time 
                                                        that is convienent for you and we will get back to you with a confirmation.
                                                        Feel free to add any additional information to help our tutors.
                                                    </p>)
                                                }
                                                {
                                                    (!props.firstConsult) && !props.create && (
                                                    <p>Let's schedule your first coffee chat with us! Set 30 minutes to an hour to understand your needs,
                                                        your goals, your interests, and most importantly your learning style. During this chat, we can also discuss 
                                                        pricing, mentorship, as well as further scheduling.
                                                
                                                    </p>)
                                                }
                                                {
                                                    props.create && (
                                                        <>
                                                            <p>hey tutor</p>
                                                            <h3>Student</h3>
                                                            <select className="student-select" onChange={(e) => handleSelectStudent(e)}>
                                                                <option unselectable=''>Select a Student</option>
                                                                {
                                                                    studentList && studentList.map((student, index) => {
                                                                        return (
                                                                            <option data-uid={student.uid} key={index}>{student.displayName}</option>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </select>
                                                            <h3>Date</h3>
                                                            <input className="date-picker" type="date" onChange={(e) => setDateValue(e.target.value)}></input>

                                                        </>
                                                        
                                                    )
                                                }

                                                <h3>Request Time</h3>
                                                <TimePicker 
                                                    onChange={onValue} 
                                                    value={props.startTime} 
                                                    use24Hours
                                                    disableClock
                                                    clearIcon={null}
                                                    //get the am or pm

                                                    locale="en-US"
                                                    />
                                                <h3>Length</h3>
                                                <div className="length-box">
                                                    <section>
                                                        <label>Hour(s)</label>
                                                        <select onChange={(e) => handleTimeChange(e, 'hour')}>
                                                                <option>0</option>
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                        </select>
                                                    </section>
                                                    <section onChange={(e) => handleTimeChange(e, 'minute')}>
                                                        <label>Minute(s)</label>
                                                        <select>
                                                                <option>0</option>
                                                                <option>15</option>
                                                                <option>30</option>
                                                                <option>45</option>
                                                        </select>
                                                    </section>
                                                </div>
                                                <h3>Additional Information</h3>
                                                <textarea 
                                                    value={textAreaValue}
                                                    placeholder="Please add any additional information here."
                                                    onChange={handleChange}>
                                                </textarea>
                                            {
                                                /* put something about timezone
                                                and reoccurring events,
                                                setting when they are free
                                                */
                                            }
                                            <button className="calendar-event-button" onClick={grabFormData}>Submit</button>
                                                
                                            </>
                                            )}

                                            { //for events
                                                props.event && (
                                                    <>  
                                                        <div className={"edit-row"}>
                                                            <h3>Start Time</h3>
                                                            <div className="edit-time">
                                                                        
                                                                        <TimePicker
                                                                            onChange={onValue}
                                                                            value={props.startTime}
                                                                            use24Hours
                                                                            disableClock
                                                                            clearIcon={null}
                                                                            //no x

                                                                            //get the am or pm
                                                                        ></TimePicker>
                                                                    </div>

                                                                    <div className="length-box">
                                                                            <section>
                                                                                <label>Hour(s)</label>
                                                                                <select defaultValue={props.durationH} onChange={(e) => handleTimeChange(e, 'hour')}>
                                                                                        <option>0</option>
                                                                                        <option>1</option>
                                                                                        <option>2</option>
                                                                                        <option>3</option>
                                                                                        <option>4</option>
                                                                                        <option>5</option>
                                                                                </select>
                                                                            </section>
                                                                            <section >
                                                                                <label>Minute(s)</label>
                                                                                <select defaultValue={props.durationM} onChange={(e) => handleTimeChange(e, 'minute')} >
                                                                                        <option>0</option>
                                                                                        <option>15</option>
                                                                                        <option>30</option>
                                                                                        <option>45</option>
                                                                                </select>
                                                                            </section>
                                                                        </div>
                                                        </div>
                
                                                        <h3>Event Details</h3>
                                                        <p>{props.title}</p>
                                                        <p>From {convertTime(value)} to {endTime(value, hour, minute)}</p>
                                                        <h5></h5>
                                                        
                                                    
                                                        <h3>Notes</h3>
                                                        <textarea 
                                                            defaultValue={props.notes}
                                                            onChange={handleChange}>
                                                            
                                                        </textarea>
                                                        
                                                        <button onClick={handleRescheduleSubmit} className="update-request">Update Request</button>
                                                    </>
                                                )
                                            }
                                        </>

                                        )
                                    }
                                </>  
                                )
                    }
                </div>
            </div>
    )
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default ScheduleSideDrawer;