import "./AlertBox.css"
import { useEffect, useState } from "react";
import { site, port } from '../EnvVariables';
import { auth } from "../../Firebase";
import AlertItem from "./AlertItem";

function AlertBox(props) {

    const [alerts, setAlerts] = useState(null);

    useEffect(() => {
        //make api call to get alertInfo
        const localUrl = `${site}:${port}/api/v1/alerts`;
        fetch(localUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({uid: auth.currentUser.uid}),
        }).then((r) => r.json())
            .then(data => {
                const count = data[1]
                const alerts = data[0]
                //loop through alerts and create alert components
                //give the first 3 alerts a class of new
                //give the rest a class of old
                for (let i = 0; i < alerts.length; i++) {
                    if (i < alerts) {
                        //add status:new to the alert
                        //object assign
                        alerts[i].seen = "new"
                    } else {
                        //add status:old to the alert
                        alerts[i].seen = "old"
                    }
                    
                }
                setAlerts(alerts)

                
            })

    }, [])
    

    return (
        <div className="alertbox-container">
            <div className="alertbox-exit" onClick={() => props.handleExitAlerts()}>
            </div>

            <div className="alertbox-content">
                {
                    //for each alert in alert, create an alert component
                    alerts && alerts.map((alert, index) => {
                        return (
                            <AlertItem key={index}
                                date={alert.date} 
                                title={alert.title}
                                endTimehm={alert.endTimehm} 
                                time={alert.time} 
                                hour={alert.hour} 
                                minute={alert.minute} 
                                seen={alert.seen} 
                                
                            />
                        )
                    })
                }
            </div>
            
        </div>
    )
}

export default AlertBox