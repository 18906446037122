
import {site, port} from './EnvVariables';


export function sendEmail(email, parentEmail, name, lastName, type){
    //console.log(type, name, email, parentEmail);
    fetch (site + port + '/api/v1/emailNewUser', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            studentEmail: email,
            parentEmail: parentEmail,
            name: name,
            lastName: lastName,
            type: type
        })
    })
    .then((response) => response.json())
    .then((responseJson) => {
        console.log(responseJson);
    }
    )
    .catch((error) => {
        console.error(error);
    }
    );

}

