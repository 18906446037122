import './JoinUs.css'
import demoImg from '../img/demo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

function JoinUs(props) {
    return (
        <div className="container-blind full">
            <h1>Start Learning Today!</h1>
            <div className="container-joinus">
                <img src={demoImg}></img>
                <div>
                    <h1>Create an account with just one click! 
                    </h1>
                    
                    <h2>
                        Use google to sign up and get started with your first free consultation call.
                        You're only a few clicks away from starting your journey to ascend!

                        <br></br>
                        <br></br>

                        Whether you're a student or a tutor, we got you covered! Join us on our mission to improve education for all.
                    </h2>



                    <button className="portal-button" onClick={props.platformClick}>
                        Take Me To the Platform
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                </div>
            </div>
        </div>
    )

}

export default JoinUs

