import './Rockets.css'


function Rocket2(){
    return (
        <div className="rocket rocket2">
        <div className="rocket-body">
          <div className="body-rocket"></div>
          <div className="fin fin-left"></div>
          <div className="fin fin-right"></div>
          <div className="window"></div>
        </div>

        <ul className="star-rocket">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
        )
}

export default Rocket2