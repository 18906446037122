import LandingPage from "./LandingPage/LandingPage";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Platform from "./Platform/Platform";
import ProtectedRoute from "./Platform/ProtectedRoute";
import { datadogRum } from '@datadog/browser-rum'
import { site} from "./Platform/EnvVariables";
import { datadogLogs } from '@datadog/browser-logs';

datadogRum.init({
  applicationId: 'f640a12a-c709-44e5-baab-fc4153266fb6',
  clientToken: 'pubb01165f950f0335cd39752efe4ae23ba',
  site: 'datadoghq.com',
  service:'ascend-academy',
  allowedTracingUrls: [site, (url) => url.startsWith(site)],
  env:'ascend',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'allow'
});


datadogLogs.init({
  applicationId: 'f640a12a-c709-44e5-baab-fc4153266fb6',
  clientToken: 'pubb01165f950f0335cd39752efe4ae23ba',
  site: 'datadoghq.com',
  service:'ascend-academy',
  session_id:datadogRum.getInternalContext().session_id,
  env:'ascend',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
})
datadogRum.startSessionReplayRecording();

function App() { 
  //const test = datadogRum.getInternalContext();
  //const test2 = datadogRum.getRumGlobalContext();
  //console.log(test)
  //console.log(test2)

  return (
    <>
    <Routes>
      <Route path="/" element={<LandingPage/>} />
      <Route path="/app" element={
        <ProtectedRoute>
          <Platform/>
        </ProtectedRoute>} />

    </Routes>
    
    
    </>
  
  )
}


export default App;
