import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <GoogleOAuthProvider clientId="593482271261-6ec7rshjhus6ngppp7bd11v03pmq8458.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
        
    </BrowserRouter>
    );
