import './Tutor.css'

function TutorCard(props){
    return (
        <figure className="snip1336">
            <img className="profile-background" src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample" + props.sample + ".jpg"} alt="sample87" />
            <figcaption>
                <img src={props.img} alt="profile-sample4" className="profile" />
                <h2>{props.name}<span>{props.job}</span></h2>
                <p>{props.description}</p>
            </figcaption>
        </figure>
    )
}

export default TutorCard