import './AlertItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {auth, db} from '../../Firebase';
import { doc, setDoc, query, collection } from "firebase/firestore"; 
import { useCollectionData } from 'react-firebase-hooks/firestore';

function AlertItem(props) {
    //createNewUser(auth.currentUser)
    //add to the db

    const user = auth.currentUser;
    const docRef = setDoc(doc(db, "users", user.uid), {
        first: "Aada",
        last: "Lovelsssace",
        born: 1815
        });

    const users = collection(db, "users");
    const q = query(users);
        
    const [usersData] = useCollectionData(q, { idField: 'id' });
    
    
    return (
        <div className='alertbox-item'>
            {
                
                usersData && usersData.map((user) => {
                    return (
                        <div>
                            <p>{user.first}</p>
                            <p>{user.last}</p>
                            <p>{user.born}</p>
                        </div>
                    )
                }
                )
                
            }
        </div>
    )
}

export default AlertItem