import './Container.css'
import TutorCard from './TutorCard'
import AmyPhoto from '../img/amy.png'
import EdPhoto from '../img/ed.png'
import davidPhoto from '../img/david.png'
import warrenPhoto from '../img/warren.JPG'
import thomasPhoto from '../img/thomas.jpeg'

function TutorSection() {
    return (
        <div className="tutor-container">
            <h1>Our Tutors</h1>
            <div className="tutor-card-box">
                <TutorCard name="Amy" sample={"87"} img={AmyPhoto} job="Dental Student" description="Hi, I’m Amy! I originally grew up in South Florida, but I’ve lived in Boston for the past 5 years. I graduated from Boston College last year with a B.S in Chemistry and a minor in Music on the Pre-Dental track. Throughout college, I have volunteered at different Boston Public Schools with our Pre-Dental Society, Women in STEM, and our Music Outreach program to share my passions with students. I’m currently working as a dental assistant, but I’ll be attending dental school next fall! In my free time, I love playing music, running, and teaching!"></TutorCard>
                <TutorCard name="Edward" sample={"86"} img={EdPhoto} job="Educator" description="I am currently a senior at Boston College, Majoring in Secondary Education with a focus on English. Through BC, I am a teaching assistant at public schools across Newton, Massachusetts, including Boston Green Academy, Brighton High school, and currently at Bigelow middle school. From these experiences, I have developed a passion for helping with classroom management and creating and teaching lessons, specifically in English, writing, and reading comprehension. Aside from my personal hobbies of lifting, watching shows/movies, and playing basketball, I enjoy tutoring in my free time!"></TutorCard>
                <TutorCard name="David" sample={"9"} img={davidPhoto} job="Medical Student" description="Hi everyone! My name is David, and I am a recent graduate from Boston College with a B.S. in Biology on the Pre-medical track. I'm currently a clinical research coordinator for Type 1 Diabetes trials at the Harvard-affiliated, Joslin Diabetes Center. I have always maintained an unwavering passion for tutoring and the empowerment that education can bring to an individual. I first started tutoring in highschool where I continued my passions in college through Boston College’s Neighborhood Center as well as the East Boston Neighborhood Health Center. I help students in English, Science, and Mathematics! Additionally, I enjoy making music, playing basketball, lifting and reading in my free time."></TutorCard>
                <TutorCard name="Warren" sample={"84"} img={warrenPhoto} job="Financial Analyst" description="Hello! My name is Warren, and I’m a BC Alumnus working in finance at the PGCC, a startup in Philly. For as long as I can remember, I’ve been fortunate enough to have great mentors and tutors both in and outside of academic settings, and they instilled in me a great love of learning. The most influential tutors I’ve had all shared a few qualities that made them great: a palpable passion for subjects, the ability to provide guidance, and perhaps most important, the ability to listen my needs as a student. Since first having the opportunity to pay my good fortune forward to younger students as a volunteer at Juren After-school Academy, I have also done my utmost to embody these qualities of great tutors."></TutorCard>
                <TutorCard name="Thomas" sample={"85"} img={thomasPhoto} job="Sales Engineer" description="Hello! My name is Thomas and I am a recent graduate of Boston College. Although I graduated as an economics major, I realized I had a passion for software development which has sparked my career at Datadog. I think that it is vital for students to understand what excites them and interests them, as ultimately there is no greater success that pursing a career that is fulfilling. I hope to share my expertise while promoting students to their full potential in school and beyond! My mentors always told me nothing was impossible! I do my best to pay it forward and push each student to enjoy learning."></TutorCard>
            </div>
        </div>
    )
}



export default TutorSection