import './RocketLoader.css';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRocket, faCloud} from '@fortawesome/free-solid-svg-icons'


function RocketLoader() {

    const handlePointerMove = (e)=>{
        const { currentTarget: el, clientX: x, clientY: y } = e;
        const { top: t, left: l, width: w, height: h } = el.getBoundingClientRect();
        el.style.setProperty('--posX',  x-l-w/2);
        el.style.setProperty('--posY',  y-t-h/2);
      }

    return (
        <div className="container-loader" onPointerMove={handlePointerMove}>
            <div className="star_container">
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
            </div> 
            <div className="loader">
                <div className="rocket-loader">
                <FontAwesomeIcon icon={faRocket} className='fas fa-rocket-loader'/>

                </div>
                <span>
                <i></i>
                </span>
            </div>
        </div>
    )
}
export default RocketLoader;