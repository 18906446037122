import './PlatformHeader.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faBell, faBook, faBarsProgress, faCalendar, faUser, faArrowRight, faDoorOpen, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function PlatformHeader(props) {
    let icon;


    switch (props.tab) {
        case 'Progress':
            icon = faBarsProgress;
            break;
        case 'Payment':
            icon = faCreditCard;
            break;
        case 'Schedule':
            icon = faCalendar;
            break;
        case 'Sessions':
            icon = faCalendarCheck;
            break;
        case 'Profile':
            icon = faUser;
            break;
        default:
            break;
    }



            

    return (
        <div className="platform-header-container">
            <div className="tab-header-box">
                <FontAwesomeIcon icon={icon}/>
                <h1>{props.tab}</h1>
            </div>
            {
            /*
            <div className="icon-header-box">
                <FontAwesomeIcon onClick={() => props.handleAlerts()} icon={faBell}></FontAwesomeIcon>
                <span className="icon-badge-bell">{props.alertCount}</span>
            </div>
            */
            }

        </div>
    )
}

export default PlatformHeader