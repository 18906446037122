import './Rocket3.css';

function Rocket3() {
    return (
            <div className="container-rocket3">
                            <div className="moon-rocket3">
                                <div className="crater crater-1"></div>
                                <div className="crater crater-2"></div>
                                <div className="crater crater-3"></div>
                                <div className="crater crater-4"></div>
                                <div className="crater crater-5"></div>
                                <div className="shadow-rocket3"></div>
                                <div className="eye-rocket3 eye-l"></div>
                                <div className="eye-rocket3 eye-r"></div>
                                <div className="mouth-rocket3"></div>
                                <div className="blush blush-1"></div>
                                <div className="blush blush-2"></div>
                            </div>
                            <div className="orbit-rocket3">
                                <div className="rocket-rocket3">
                                    <div className="window-rocket3"></div>
                                </div>
                            </div>
                        </div>
    );
    }

    export default Rocket3;