import "./CardPopup.css"
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeftLong, faCalculator, faFlask, faBook, faLaptopCode, faClipboardQuestion, faIdBadge, faEarthAmericas, faBriefcase, faClock, faGlobeAmericas} from '@fortawesome/free-solid-svg-icons'

import officePhoto from '../img/officehours.jpg'
import programmingPhoto from '../img/programming.jpg'
import resumePhoto from '../img/resume.jpg'



function CardPopup(props) {
    const academicTutoringText = 'We have a wide-range of mentors that are more than qualified to tutor any subject. Our mentors will work with your student to identify strengths and areas of improvement, to ultimately develop a personalized approach to learning that will yield results. Flexible scheduling with your mentor will guarantee full preparation for quizzes, exams, and assignments. We intend to nurture the strategies–such as time management, prioritization, problem-solving, and efficiency–that will help students for their lifetime.'
    const careerDevelopmentText = 'We are more than aware of the confusion that comes with choosing a fitting career path, and we’re here to help. Whether your student intends to pursue medicine, engineering, business, law, computer science, or any other profession, our goal is to provide a greater perspective for what each path entails–every step of the way. Additionally, we aim to work with your student to analyze their interests and personal qualities to bring greater understanding to which jobs may provide the greatest fulfillment. This program stems from the core belief that with strong understanding comes an even stronger resolve for success. '
    const standardizedTestText = 'Preparing students for the SAT, ACT, or AP courses can seem like a daunting task, but it doesn’t have to be! Work with a mentor to implement time-tested strategies for conquering any standardized exam. We can help your student not only to memorize high-yield information, but also to fully understand topics and best apply their knowledge to exam questions. We prioritize achieving the best results in the least amount of time possible!'
    const officeHoursText = "We offer office hours for students to come in and work on their homework, projects, or assignments. We have a wide range of mentors that are more than qualified to tutor any subject. Our mentors will work with your student to identify strengths and areas of improvement, to ultimately develop a personalized approach to learning that will yield results. Flexible scheduling with your mentor will guarantee full preparation for quizzes, exams, and assignments. We intend to nurture the strategies–such as time management, prioritization, problem-solving, and efficiency–that will help students for their lifetime."
    let photo;
    let icon;
    let text;
    switch (props.type) {
        case 'Academic Tutoring':
            photo = programmingPhoto;
            icon = faBook
            text = academicTutoringText
            break;
        case 'Career Development':
            photo = resumePhoto;
            icon = faIdBadge
            text = careerDevelopmentText
            break;
        case 'Standardized Tests':
            photo = resumePhoto;
            icon = faClipboardQuestion
            text = standardizedTestText
            break;
        case 'Office Hours':
            photo = programmingPhoto
            icon = faClock
            text = officeHoursText
            break;
        default:
            photo = resumePhoto
            icon = faBook
            break;
    }



    return (
        <div className="card-popup-wrapper">
            <div className="card-popup-container-header-box">
                <div className="card-popup-container-header" onClick={props.onExit}>
                    <FontAwesomeIcon icon={faArrowLeftLong}/>
                    <p>Back</p>
                </div>
            </div>
            
            <div className="card-popup-container">
                <div className="card-popup-img-container">
                    <img src={photo} alt="card popup"/>
                </div>
                <section>
                    <div>
                        <FontAwesomeIcon icon={icon}/>
                        <h1>{props.type}</h1>
                    </div>
                    <article>
                        <p>{text}
                        </p>
                    </article>
                </section>
                
            </div>
        </div>
    )
}

export default CardPopup