import "./StudentPayment.css";
import { useState, useEffect } from "react";
import PlatformHeader from "../PlatformHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSearch, faPen, faCalendarCheck, } from "@fortawesome/free-solid-svg-icons";
import { auth, getCard, userCheck } from '../../../Firebase'
import StudentPaymentIntent from "./StudentPaymentIntent";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Loader from "../Loader";
import { site, port } from '../../EnvVariables';
import StudentPaymentPage from "./StudentPaymentPage";

function StudentPayment(props) {
    const user = auth.currentUser;
    var currentMonth = new Date().getMonth() + 1;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    currentMonth = months[currentMonth - 1];
    const [studentUid, setStudentUid] = useState(user.uid);
    const [paymentAdded, setPaymentAdded] = useState(false)
    const [loading, setLoading] = useState(true)
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(false)
    
    const [cardBrand, setCardBrand] = useState(false)
    const [cardLast4, setCardLast4] = useState(false)
    const [cardExpYear, setCardExpYear] = useState(false)

    const [attendedSessions, setAttendedSessions] = useState(0)
    const [totalHours, setTotalHours] = useState(0)
    const [totalMinutes, setTotalMinutes] = useState(0)

    const getStripePromise = () => {
        const localUrl = `${site}${port}/publishable-key`;
        fetch(localUrl)
            .then((r) => r.json())
            .then(data => {
                //console.log(data)
                setStripePromise(loadStripe(data.publishable_key))
            }
            )
    }

    const getClientSecret = () => { 
        const localUrl = `${site}${port}/create-payment-intent`;
        fetch(localUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        }).then((r) => r.json())
            .then(data => {
                //console.log(data)
                setClientSecret(data.client_secret)
                //setLoading(false)
            })
    }

    const grabCardDetails = (uid) => {
        setLoading(true)
        getCard(uid).then((card) => {
            if (card.status !== "error") {
                //payment was already added
                setCardBrand(card.brand)
                setCardLast4(card.last4)
                setCardExpYear(card.exp_year)
                setLoading(false)
                setPaymentAdded(true)
            }
            else {
                //payment was not added
                getStripePromise()
                getClientSecret()
                setLoading(false)
                setPaymentAdded(false)
            }
        })
    }

    useEffect(() => {
        //make api call and check if payment if set for uid
        userCheck(user.uid, user.email).then(
            (result) => {
                setAttendedSessions(result.totalSessions)
                if (result.minutesBillable === 0) {
                    setTotalHours(0)
                    setTotalMinutes(0)
                }
                if (result.minutesBillable < 60) {
                    setTotalHours(0)
                    setTotalMinutes(result.totalMinutes ? result.totalMinutes : 0)
                }
                else if (result.minutesBillable > 60) {
                    const hours = Math.floor(result.minutesBillable / 60)
                    const minutes = result.minutesBillable % 60
                    setTotalHours(hours)
                    setTotalMinutes(minutes)
                }
                setStudentUid(result.uid);
                grabCardDetails(result.uid)
            }
        )
    }, [])

    function inputSelected(e)  {
        e.target.parentNode.style.backgroundColor = "#8dbbff27";
        e.target.parentNode.style.border = "1px solid #0076e17d";
        //setDisplay(true);
    }

    const handlePaymentMethod = (bool, cardDetails) => {

        if (bool) {
            //payment was already added
            setCardBrand(cardDetails.brand)
            setCardLast4(cardDetails.last4)
            setCardExpYear(cardDetails.exp_year)
            //console.log(cardExpYear)
            setPaymentAdded(bool)
            
        }
        else {
            //payment was not added
            getStripePromise()
            getClientSecret()
            setPaymentAdded(bool)
        }
    }

    return (
        <div className="platform-right-container">
            <PlatformHeader handleAlerts={props.triggerAlertBox} tab="Profile"></PlatformHeader>


            {loading && <Loader></Loader>}
            
            {
                !loading && (
                    //<StudentPaymentPage card={cardDetails} changeCard={() => handlePaymentMethod(false, false)}></StudentPaymentPage>
                    <div className="student-payment-page">
                        <h1>Profile</h1>
                        <h2>Its that gud gud</h2>
                        <h1>Payment Details</h1>
                        <div className="payment-container-row">
                            <h2>Month of {currentMonth}</h2>
                            <div className="card-details">
                                    <div className="current-month-box">
                                        <h4 className="attended-sessions-count">{attendedSessions}</h4>
                                        <div>
                                            <h3>Attended Sessions</h3>
                                            <h5>{totalHours} hours and {totalMinutes} minutes</h5>
                                        </div>
                                    </div>
            
                                    <span  onClick={() => props.redirect("sessions")}>Sessions
                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                    </span>

                            </div>
                        </div>
                        <div className="payment-container-row">
                            <h2>Payment Method</h2>
                            <div className="card-details">

                            {

                                !paymentAdded && stripePromise && clientSecret && !loading && (
                                    <Elements stripe={stripePromise} options={{clientSecret}} >
                                        <StudentPaymentIntent 
                                            uid={studentUid}
                                            triggerPaymentPage={(status, cardDetails) => {handlePaymentMethod(status, cardDetails)}}

                                        ></StudentPaymentIntent>
                                    </Elements>
                                )
                            }
                            {
                                paymentAdded && (
                                    <>
                                        <div>
                                        <p>{cardBrand.toUpperCase()} ending in <b>{cardLast4}</b></p>
                                        <p>Expires {cardExpYear}</p>
                                        </div>
                                        <span  onClick={() => handlePaymentMethod(false, false)}>Edit
                                                <FontAwesomeIcon icon={faPen}/>
                                        </span>
                                    </>
                                )
                            }
                                
                                

                            </div>
                            
                            
                        </div>
                        
                        {
                            /*

                            <div className="payment-container-row">
                            <h2>Previous Months</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Month</th>
                                        <th>Attended Sessions</th>
                                        <th>Hours</th>
                                    </tr>
                                    <tr>
                                        <td>February (2023)</td>
                                        <td>5</td>
                                        <td>14.5</td>
                                    </tr>
                                    <tr>
                                        <td>March (2023)</td>
                                        <td>5</td>
                                        <td>14.5</td>
                                    </tr>
                                    <tr>
                                        <td>February (2023)</td>
                                        <td>5</td>
                                        <td>14.5</td>
                                    </tr>

                                    
                                </tbody>
                            </table>
                        </div>
                        */
                        }
                        

                        <div className="faq">
                            <h1>FAQ</h1>
                            <div className="faq-container">
                                <div className="faq-question">
                                    <h3>What is the payment method?</h3>
                                    <p>Payment method is the card that is used to pay for the sessions. You can add a new card or edit the existing card.</p>
                                </div>
                                <div className="faq-question">
                                    <h3>What is the attended sessions?</h3>
                                    <p>Attended sessions are the sessions that you have attended in the current month. You can view the sessions in the calendar.</p>
                                </div>
                                <div className="faq-question">
                                    <h3>When do I get charged?</h3>
                                    <p>Your payment method card will be automatically charged on the first of every month.</p>
                                </div>
                                <div className="faq-question">
                                    <h3>For any payment questions or general inquiries please email info@ascendacademy.io</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            

        </div>
    )

}

export default StudentPayment;