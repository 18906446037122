import "./Profile.css"
import PlatformHeader from "../PlatformHeader"
import { auth } from "../../../Firebase";

function StudentProfile(props) {

    const events = [
        {
            type: "schedule",
            title:'test',
            day: "1",
            month: "February",
            year: "2023",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "notification",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "schedule",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "notification",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "schedule",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "notification",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "schedule",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        },
        {
            type: "notification",
            title:'test',
            day: "2",
            month: "December",
            year: "2022",
            time:"12:00 PM",
            location:"Zoom",
            description:"This is a test event",
            tutor: "Tutor Name",
            fullDate: "2023-02-01"
        }

    ]
    const user = auth.currentUser;
    const firstName = user.displayName.split(" ")[0];
    const lastName = user.displayName.split(" ")[1];
    const email = user.email;
    const img = user.photoURL;



    console.log(user);
    console.log(img)
    return (
        <div className="student-profile-container">
            <PlatformHeader handleAlerts={props.triggerAlertBox}  tab="Profile"></PlatformHeader>
            <div className="student-profile-scroll-wrapper">

                <div className="student-profile-top">
                    <section>
                        <img src={img} referrerpolicy="no-referrer" alt="profile"></img>
                        <h3>{firstName + " " + lastName}</h3>
                        <p>{email}</p>
                        <p>Joined 2023</p>
                    </section>

                    <section className="profile2">
                        <div className="profile-row">

                        </div>
                        <div className="profile-row">
                            
                        </div>
                    </section>
                </div>

                <div className="student-profile-events-container">
                    <h1>Your Events</h1>
                    {
                        events.map((event, index) => {
                            return (
                                <div className="student-profile-event" onClick={() => props.handleEventClick(event.fullDate, event.time)} key={index}>
                                    <div className="student-profile-event-date">
                                        <h2>{event.time}</h2>
                                        <h3>{event.day + " " + event.month}</h3>
                                        <h4>{event.year}</h4>
                                    </div>
                                    <div className="student-profile-event-info">
                                        <div>
                                            <h5>{event.location}</h5>
                                            <p>With {event.tutor}</p>
                                        </div>
                                        <div>
                                            <h1>{event.title}</h1>
                                            <p>{event.description}</p>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </div>
    )

}

export default StudentProfile