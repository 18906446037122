import "./Pricing.css"
import planet from '../img/planet.svg'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRocket, faStar, faUserAstronaut, faMoon, faMeteor} from '@fortawesome/free-solid-svg-icons'


function Pricing(props) {
    return (
        <div className="pricing-container">
            <img className="pricing-planet" src={planet}></img>
            <span className="pricing-left-title">What You Pay</span>
            <span className="pricing-right-title">What You Get</span>
            <span className="pricing-mobile">Pricing</span>
            <div className="pricing-box">
                <div className="border-right">
                    <h1>$70</h1>
                    <p>Per Hour</p>
                    <i>*Economy pricing available</i>
                </div>
                <div className="align-left">
                    <span>
                        <FontAwesomeIcon icon={faRocket}></FontAwesomeIcon>
                        <h2>Personalized mentors to guide your education</h2>
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                        <h2>Sessions and Meetings that fit your schedule</h2>
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faUserAstronaut}></FontAwesomeIcon>
                        <h2>Weekly and monthly reports on your progress and goals</h2>
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMoon}></FontAwesomeIcon>
                        <h2>Mentors that match you</h2>
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faMeteor}></FontAwesomeIcon>
                        <h2>Community Office Hours</h2>
                    </span>
                
                </div>
            </div>
        </div>
    )
}

export default Pricing