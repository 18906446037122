
import { initializeApp} from "firebase/app";
import { getAuth, onAuthStateChanged ,GoogleAuthProvider, signInWithPopup, signInWithRedirect, signOut, createUserWithEmailAndPassword } from "firebase/auth";


import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData} from 'react-firebase-hooks/firestore';
import { getFirestore, doc, setDoc, updateDoc, deleteDoc, getDocs, query, collection, QuerySnapshot, where, getDoc, getDb } from "firebase/firestore"; 

/*
Where do i need my realtime db
1. creation of user (redo first time page)
2. events
3. each page needs their own db use effect hook
4. Changing pages does not recall db
5. 

Collections
- users
- uid (specific events)
- notifications





*/

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDVjURWoikKVLWCKvr6uKPEYA4n_A-gdSY",
  authDomain: "ascend-academy.firebaseapp.com",
  projectId: "ascend-academy",
  storageBucket: "ascend-academy.appspot.com",
  messagingSenderId: "970905486095",
  appId: "1:970905486095:web:f903ec1ab8930796e4747a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const provider = new GoogleAuthProvider();
//get the current signed in user



export const signInWithGoogle = () => {
    console.log("signing in with google")
    return new Promise((resolve, reject) => {
        signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            resolve(user);
        }
        ).catch((error) => {
            reject(error);
        }
        )
    
    })
}

export const signOutUser = () => {
    signOut(auth).then(() => {
        console.log("signed out")
        return true
    }).catch((error) => {
        console.log(error)
        return false
    })
}

export const createUserWithEmail = (fname, lname, email, parentEmail, password) => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            //signed in
            const user = userCredential.user;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        })

}

export const createNewUser = (user) => {
    const userRef = db.collection("users").doc(user.uid);
    const name = user.displayName.split(" ");
    const firstName = name[0];
    const lastName = name[1];
    userRef.set({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        uid: user.uid,
        firstName: firstName,
        lastName: lastName,
        parentEmail: "",
        parentPhone: "",
        phone: "",
        school: "",
        grade: "",
    })
}

export const getUser = async (uid) => {
    const QuerySnapshot = await getDoc(doc(db, "users", uid));
    if (QuerySnapshot.exists()) {
        return QuerySnapshot.data()
    }
    else {
        return {userType: "new"}
    }
}

export const getEvent = async (eventId) => {
    const QuerySnapshot = await getDoc(doc(db, "events", eventId));
    if (QuerySnapshot.exists()) {
        return QuerySnapshot.data()
    }
    else {
        return {status: "error"}
    }
}

export const newAlert = async (uid, alert) => {
    const alertRef = db.collection("users").doc(uid).collection("alerts").doc();
    alertRef.set({
        alert: alert,
        id: alertRef.id,
        read: false,
        time: Date.now()
    })
}

export const getStudents = async (uid) => {
    //first check if the given uid matches a tutor or admin
    //then query all the users with userType: student
    //get the corresponding uids for each student

    const QuerySnapshot = await getDoc(doc(db, "users", uid));
    if (QuerySnapshot.exists()) {
        const userType = QuerySnapshot.data().userType;
        if (userType === "tutor" || userType === "admin") {
            const QuerySnapshot = await getDocs(collection(db, "users"));
            const students = [];
            QuerySnapshot.forEach((doc) => {
                if (doc.data().userType === "student") {
                    //push only displayName, email, and uid
                    students.push({
                        displayName: doc.data().firstName + " " + doc.data().lastName,
                        email: doc.data().email,
                        uid: doc.data().uid
                    })
                }
            })
            return students;
        }
        else {
            return {status: "error"}
        }
    }

}

export const getCard = async (uid) => {
    const QuerySnapshot = await getDoc(doc(db, "users", uid));
    if (QuerySnapshot.exists()) {
        //get the card_last4, card_brand, and card_exp_year
        const data = QuerySnapshot.data();
        if ((data.card_last4 === null) || (data.card_brand === null) || (data.card_exp_year === null)) {
            return {status: "error"}
        }
        else {
            return {
                status: "success",
                last4: data.card_last4,
                brand: data.card_brand,
                exp_year: data.card_exp_year
                
            }
        }
    }
    else {
        return {status: "error"}
    }
}

export const addSecondaryEmail = (uid, secondaryEmail, primaryEmail, accountName) => {
    const userRef = setDoc(doc(db, "userTypeCheck", secondaryEmail), {
        accountUid: uid,
        accountEmail: primaryEmail,
        secondaryEmail: secondaryEmail,
        accountName: accountName,
        uid: false //if false, they didn't sign in yet
    })
}

export const levelUp = async (uid, level) => {
    const userDoc = await getDoc(doc(db, "users", uid));
    console.log(userDoc)
    if (userDoc.exists()) {
        //check if the current level is less than the new level
        if (userDoc.data().level < level) {
            //update the level
            updateDoc(doc(db, "users", uid), {
                level: level
            })
        }
    }
    else {
        //look for doc in userTypeCheck with {uid: uid}
        const docs = await getDocs(collection(db, "userTypeCheck"));
        let secondaryUser = null;
        docs.forEach((doc) => {
            if (doc.data().uid === uid) {
                secondaryUser = doc;
            }
        })

        if (secondaryUser.exists()) {
            //get the accountUid
            console.log(secondaryUser.data())
            const accountUid = secondaryUser.data().accountUid;

            //check if the current level is less than the new level
            const accountDoc = await getDoc(doc(db, "users", accountUid));
            if (accountDoc.data().level < level) {
                updateDoc(doc(db, "users", accountUid), {
                        level: level
                    })
                }
            }
        
    
    }
}



export const userCheck = async (uid, email) => {
    const QuerySnapshot = await getDoc(doc(db, "users", uid));
    if (QuerySnapshot.exists()) {
        return QuerySnapshot.data()
    }
    else {
        const QuerySnapshot = await getDoc(doc(db, "userTypeCheck", email));
        if (QuerySnapshot.exists()) {
            const data = QuerySnapshot.data();
            if (data.uid === uid) {
                return getUser(data.accountUid);
            }
            else if (data.uid === false) {
                return {userType: "invited", accountEmail: data.accountEmail, accountName:data.accountName}
            }
        }
        else {
            return {userType: "new"}
        }
    }
}

export const deleteSecondaryEmail = (secondaryEmail) => {
    getDoc(doc(db, "userTypeCheck", secondaryEmail))
    .then((document) => {
        if (document.exists()) {
            const accountUid = document.data().accountUid;
            //go to users collection and delete the secondary email
            updateDoc(doc(db, "users", accountUid), {
                parentEmail: null}
            )
            deleteDoc(doc(db, "userTypeCheck", secondaryEmail))
            return true;
        }
        else {
            return false;
        }
    }
    )
}

export const confirmSecondaryEmail = (uid, secondaryEmail) => {
    getDoc(doc(db, "userTypeCheck", secondaryEmail))
    .then((document) => {
        if (document.exists()) {
            updateDoc(doc(db, "userTypeCheck", secondaryEmail), {
                uid: uid}
            )
            return true;
        }
        else {
            return false;
        }
    }
    )
}


 