import "./SideMenu.css";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsProgress, faCalendarCheck, faCalendar, faUser, faArrowRight, faDoorOpen, faBook, faCreditCard, faPerson} from '@fortawesome/free-solid-svg-icons'


function SideMenu(props) {


    const handleTabClick = (e, selected) => {
        //add side-bar-active class to clicked tab
        props.triggerTabChange(selected)
    }


    return (
        <div className="side-menu-container">
            <h1>Ascend Academy
            </h1>

            <div className="tab" onClick={(e) => handleTabClick(e, 'progress')}>
                <span>
                <FontAwesomeIcon  icon={faBarsProgress}/>
                Progress
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            {/*
            <div className="tab" onClick={() => handleTabClick('subjects')}>
                <span>
                <FontAwesomeIcon icon={faBook}/>
                Subjects
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            */}
            <div className="tab" onClick={(e) => handleTabClick(e, 'schedule')}>
                <span>
                <FontAwesomeIcon icon={faCalendar}/>
                Schedule
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            <div className="tab" onClick={(e) => handleTabClick(e, 'sessions')}>
                <span>
                <FontAwesomeIcon icon={faCalendarCheck}/>
                Sessions
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            {
            /*
            <div className="tab" onClick={(e) => handleTabClick(e, 'profile')}>
                <span>
                <FontAwesomeIcon icon={faUser}/>
                Profile
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            */
            }
            
            <div className="tab" onClick={(e) => handleTabClick(e, 'profile')}>
                <span>
                <FontAwesomeIcon icon={faUser}/>
                Profile
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            {/*
            
            <div className="tab" onClick={(e) => handleTabClick(e, 'logout')}>
                <span>
                <FontAwesomeIcon icon={faDoorOpen}/>
                Log Out
                </span>
                <FontAwesomeIcon className="invis" icon={faArrowRight}/>
            </div >
            */}
        </div>
    )
    }

export default SideMenu;