import { useEffect, useState } from "react";
import './Notification.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBell, faBook, faCalendarCheck, faCreditCard, faDoorOpen, faBarsProgress, faUser } from "@fortawesome/free-solid-svg-icons";


function Notification(props) {
    //props.type

    return (
        <div className="notification-container">
            <h1>{props.message}</h1>
            <FontAwesomeIcon icon={faCalendarCheck}/>
        </div>
    )
}

export default Notification