import "./Platform.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signOutUser, userCheck } from "../Firebase";
import RocketLoader from "./Components/RocketLoader";
import SideMenu from "./Components/SideMenu";
import StudentProgress from "./Components/Student/StudentProgress";
import FirstTime from "./Components/FirstTime";
import PlatformHeader from "./Components/PlatformHeader";
import StudentSchedule from "./Components/Student/StudentSchedule";
import StudentProfile from "./Components/Student/StudentProfile";
import { auth, db, getUser, deleteSecondaryEmail, confirmSecondaryEmail } from "../Firebase";

import { useCollectionData  } from 'react-firebase-hooks/firestore';
import { firstTimeDBCheck } from "./ApiFunctions";
import StudentPayment from "./Components/Student/StudentPayment";
import StudentSessions from "./Components/Student/StudentSessions";
import AdminSessions from "./Components/Tutor/AdminSessions";
import AlertBox from "./Components/AlertBox";
import Invited from "./Components/Invited";

function Platform(props) {

    const [firstTime, setFirstTime] = useState(true);
    const [tutor, setTutor] = useState(null);
    const user = auth.currentUser;
    const [alertBox, setAlertBox] = useState(false);
    const [invited, setInvited] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(false);
    const [selectedTime, setSelectedTime] = useState(false);
    const [selectedHour, setSelectedHour] = useState(false);
    const [selectedMinute, setSelectedMinute] = useState(false);
    const [selectedEndTime, setSelectedEndTime] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [loader, setLoader] = useState(true);
    const [tabPage, setTabPage] = useState("progress");
    const [tutorStatus, setTutorStatus] = useState(false);

    const navigate = useNavigate();
    const redirectToMain = () => {
        navigate("/");
    }

    //<button onClick={signOutUser ? redirectToMain : alert("Failed to Sign Out")}>Sign Out</button>

    const convertDate = (date) => {
        //convert 2023-01-19 to Sat Jan 07 2023 15:58:05 GMT-0800 (Pacific Standard Time)
        const dateArray = date.split('-');
        const arrayOfMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const month = arrayOfMonths[parseInt(dateArray[1]) - 1];

        const newDate = new Date(`${month} ${dateArray[2]}, ${dateArray[0]} 00:00:00`);
        return newDate;

    }

    const handleEventUnselect = () => {
        setSelectedEvent(false);
        setSelectedTime(false);
    }
    const handleTabChange = (tab) => {
        switch (tab) {
            case "progress":
                setTabPage("progress");
                
                break;
            case "payment":
                setTabPage("payment");
                break;
            case "schedule":
                setTabPage("schedule");
                break;
            case "sessions":
                setTabPage("sessions");
                break;
    

            case "profile":
                setTabPage("profile");
                break;
            case "logout":
                signOutUser();
                setLoader(true);
                setTimeout(() => {
                    setLoader(false);
                    redirectToMain();
                    //add class to container to animate in
                }, 1000);
                
            break;
        }
    }


    const firstTimeTutor = () => {
        setTutor(true);
        setFirstTime(false);
        setLoader(false)
    }
    const firstTimeStudent = () => {
        setTutor(false);
        setFirstTime(false);
        setLoader(false)
    }

    const firstTimeLoader = () => { //called to show the loader component then process the first time user check
        setLoader(true);
    }

    const triggerEventCalendar = (data) => {
        setTabPage("schedule");
        setSelectedEvent(data.date);
        setSelectedTime(data.time);
        setSelectedHour(data.hour);
        setSelectedMinute(data.minute);
        setSelectedEndTime(data.endTimehm);
        setSelectedNotes(data.additionalInfo);
        setSelectedTitle(data.title);
        setSelectedEventId(data.eventId);
        
    } 

    const handleContinueInvite = () => {
        setLoader(true);
        //set the userTypeCheck email value to uid = uid
        confirmSecondaryEmail(user.uid, user.email)
        setLoader(false);
        setInvited(false)
    }
    const handleNoInvite = () => {
        setLoader(true);
        //delete the doc in userTypeCheck and remove the parent email in the student Doc
        deleteSecondaryEmail(user.email)

        setInvited(false);
        setFirstTime(true);
        setLoader(false);
    }


    useEffect(() => {
        //api call to check
        //grab the ui
        const fname = (user.displayName).split(" ")[0];
        const lname = (user.displayName).split(" ")[1];
        const email = user.email;
        const userData = {uid: user.uid, fname: fname, lname: lname, email: email}

        const data = {
            uid: user.uid,
            email: email,
                }

        //check the uid, if student, if parent,
        //if parent, get the student uid and 

        userCheck(user.uid, user.email).then(
                    (result) => {
                        switch (result.userType) {
                                    case "pending-tutor":
                                        setTutor(true);
                                        setFirstTime(true);
                                        setLoader(false);
                                        setTutorStatus("tutor");
                                        break;
                                    case "tutor":
                                        setTutor(true);
                                        setFirstTime(false);
                                        setLoader(false);
                                        break;
                                    case "admin":
                                        setTutor(true);
                                        setFirstTime(false);
                                        setLoader(false);
                                        break;
                                    case "student":
                                        setLoader(false);
                                        setTutor(false);
                                        setFirstTime(false);
                                        break;
                                    case "parent":
                                        setLoader(false);
                                        setTutor(false);
                                        setFirstTime(false);
                                        //get data from the database to populate the parent page
                                        break;
                                    case "new":
                                        setFirstTime(true);
                                        setLoader(false);
                                        break;

                                    case "invited":
                                        setFirstTime(false);
                                        setLoader(false);
                                        setInvited({email: result.accountEmail,
                                                    fname: result.accountName});
                                        break;
                                    default:
                                        break;
                                //student, tutor, or new
                                }
                        return result;
                    }
        );
        }, [])

    const handleRescheduleRedirect = (data) => {
        triggerEventCalendar(data);
        
    }
    
    return (

        <div className="platform-background">
            {loader ? (
                <RocketLoader></RocketLoader>
            ) : (
                <div className="platform-container">

                    {firstTime && !invited && (
                        <FirstTime tutor={firstTimeTutor} tutorStatus={tutorStatus} studentLoader={firstTimeLoader} student={firstTimeStudent}></FirstTime>
                    )}

                    {!firstTime && !invited &&(
                        <div>

                        {tutor && (
                            <div className="page">
                                <SideMenu triggerTabChange={handleTabChange}></SideMenu>
                                
                                {tabPage === "progress" && (

                                    <div className="tutor-progress">
                                        progress
                                    </div>
                                )}

                                {tabPage === "schedule" && (
                                    <div >
                                        schedule
                                    </div>
                                )}

                                {tabPage === "sessions" && (
                                    <AdminSessions triggerAlertBox = {() => setAlertBox(true)}></AdminSessions>
                                )}

                                {/*
                                tabPage === "profile" && (
                                    <div >
                                        schedule
                                    </div>
                                )*/}

                                {tabPage === "payment" && (
                                    <div>
                                    </div>
                                )}
                            </div>
                            )}
                        
                        {!tutor && (
                            <div className="page">
                                <SideMenu  triggerTabChange={handleTabChange}></SideMenu>
                                
                                {tabPage === "progress" && (
                                    <StudentProgress
                                        triggerAlertBox = {() => setAlertBox(true)}
                                        redirect={(page)=>setTabPage(page)}
                                        rescheduleRedirect={(data) => handleRescheduleRedirect(data)}
                                    ></StudentProgress>
                                )}
                                
                                {
                                tabPage === "profile" && (
                                    <StudentPayment 
                                        triggerAlertBox = {() => setAlertBox(true)}
                                        redirect={(page)=>setTabPage(page)}
                                    ></StudentPayment>
                                )}

                                {tabPage === "sessions" && (
                                    <StudentSessions 
                                        triggerAlertBox = {() => setAlertBox(true)}
                                        rescheduleRedirect={(data) => handleRescheduleRedirect(data)}
                                    
                                    ></StudentSessions>
                                )}

                                {tabPage === "schedule" && (
                                    <StudentSchedule 
                                        triggerAlertBox = {() => setAlertBox(true)}
                                        unselectEvent={handleEventUnselect} 
                                        initialEvent={selectedEvent ? selectedEvent : new Date()} 
                                        initialTime={selectedTime} 
                                        initialHour={selectedHour}
                                        initialMinute={selectedMinute}
                                        initialEndTime={selectedEndTime}
                                        initialNotes={selectedNotes}
                                        initialTitle={selectedTitle}
                                        eventStatus={selectedEvent ? true : false}
                                        eventId = {selectedEventId}
                                        firstConsult={false}></StudentSchedule>
                                )}

                                
                                {
                                /*
                                tabPage === "profile" && (
                                    <StudentProfile triggerAlertBox = {() => setAlertBox(true)} handleEventClick={triggerEventCalendar}></StudentProfile>
                                    )
                                */}
                                
                                
                                {alertBox && 
                                <AlertBox
                                    handleExitAlerts={() => setAlertBox(false)}
                                ></AlertBox>}
                            </div>

                        )}
                        
                        </div>
                    )}

                    {
                    invited && (
                        <Invited 
                            fname={invited.fname}
                            inviteEmail={invited.email} 
                            continue={()=>handleContinueInvite()}
                            no={()=>handleNoInvite()}
                        ></Invited>
                    )
                    }
                </div>
            )}
        </div>
    )
}
export default Platform;


