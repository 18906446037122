
import './TitleBox.css'
import { Typewriter } from 'react-simple-typewriter'

function TitleBox(props) {
    const strings = ['Academics', 'Interests', 'Passions', 
    'Career', 'Mind', 'Curiosity']
    return (
        <div className="titlebox_container">
            <h1>Elevate your</h1>
            <div>
                <Typewriter 
                            words={['Academics', 'Education', 'Projects', 'Passions']}
                            loop={5}
                            cursor
                            cursorStyle='...'
                            typeSpeed={60}
                            deleteSpeed={60}
                            delaySpeed={3000}
                            
                ></Typewriter>
            </div>

        </div>
    )
}

export default TitleBox