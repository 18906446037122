import './Cards.css'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalculator, faFlask, faBook, faLaptopCode, faClipboardQuestion, faIdBadge, faEarthAmericas, faBriefcase, faClock, faGlobeAmericas} from '@fortawesome/free-solid-svg-icons'

function Cards(props){

    return (
        <div id="cards">
                <div className="card" data-type="Academics" onClick={() => props.onCardClick("Academic Tutoring")}>
                  <div className="card-content">
                    <div className="card-image">
                        <FontAwesomeIcon icon={faBook}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">

                        <div className="card-info-title">
                          <h3>Academics</h3>  
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" data-type="Career" onClick={() => props.onCardClick("Career Development")}>
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faIdBadge}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Career</h3>  
                        </div>    
                      </div>  
                    </div>
                  </div>
                </div>
                <div className="card" onClick={() => props.onCardClick("Standardized Tests")} data-type="SAT/ACT">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faClipboardQuestion}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>SAT/ACT/AP</h3>  
                          
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={() => props.onCardClick("Office Hours")} data-type="Office Hours">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faClock}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Office Hours</h3>  
                          
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>


                {/*
                <div className="card" data-type="Mathematics" onClick={handleCardClick}>
                  <div className="card-content">
                    <div className="card-image">
                        <FontAwesomeIcon icon={faCalculator}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">

                        <div className="card-info-title">
                          <h3>Mathematics</h3>  
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" data-type="Science" onClick={handleCardClick}>
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faFlask}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Sciences</h3>  
                        </div>    
                      </div>  
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="English">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faBook}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>English</h3>  
                          
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" data-type="Programming" onClick={handleCardClick}>
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faLaptopCode}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Programming</h3>  
                          
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="Standardized Tests">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faClipboardQuestion}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Standardized Tests</h3>  
                        
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="Resume Building">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faIdBadge}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Resume Building</h3>  
                        
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="History">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faEarthAmericas}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>History</h3>  
                        
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="Business/Entrepreneurship">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faBriefcase}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Business</h3>  
                        
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card" onClick={handleCardClick} data-type="Community Office Hours">
                  <div className="card-content">
                    <div className="card-image">
                    <FontAwesomeIcon icon={faClock}/>
                    </div>
                    <div className="card-info-wrapper">
                      <div className="card-info">
                        <div className="card-info-title">
                          <h3>Office Hours</h3>  
                          
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
                */}
            </div>
    )
}

export default Cards