import './Tutor.css'

function Tutor(){
    return (
    <div className="tutor-wrapper">
    <div className="border-circle" id="one"></div>
    <div className="border-circle" id="two"></div>
    <div className="background-circle">
      <div className="triangle-light"></div>
      <div className="tutor-body"></div>
      <span className="shirt-text">A</span>
      <span className="shirt-text">S</span>
      <span className="shirt-text">C</span>
      <span className="shirt-text">E</span>
      <span className="shirt-text">N</span>
      <span className="shirt-text">D</span>
      <div className="triangle-dark"></div>
    </div>
    <div className="tutor-head">
      <div className="ear" id="left"></div>
      <div className="ear" id="right"></div>
      <div className="hair-main">
        <div className="sideburn" id="left"></div>
        <div className="sideburn" id="right"></div>
        <div className="hair-top"></div>
      </div>
      <div className="tutor-face">
        <div className="hair-bottom"></div>
        <div className="tutor-nose"></div>
        <div className="eye-shadow" id="left">
          <div className="eyebrow"></div>
          <div className="tutor-eye"></div>
        </div>
        <div className="eye-shadow" id="right">
          <div className="eyebrow"></div>
          <div className="tutor-eye"></div>
        </div>
        <div className="tutor-mouth"></div>
        <div className="shadow-wrapper">
          <div className="shadow"></div>
        </div>
      </div>
    </div>
    <span className="music-note" id="one">Science</span>
    <span className="music-note" id="two">Math</span>
  </div>
    )
}

export default Tutor