import '../Student/Sessions.css';
import PlatformHeader from '../PlatformHeader';
import { auth, db } from '../../../Firebase';
import { useState, useEffect } from 'react';
import { site, port } from '../../EnvVariables';
import Event from '../Event';
import Loader from '../Loader';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { doc, setDoc, query, collection, where, getDocs, orderBy, limit } from "firebase/firestore"; 
import { useCollectionData } from 'react-firebase-hooks/firestore';
import ScheduleSideDrawer from '../ScheduleSideDrawer';

function AdminSessions(props){
    //get todays    
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const user = auth.currentUser;
    const [confirmed, setConfirmed] = useState(false);
    const [updates, setUpdates] = useState(false);
    const [pending, setPending] = useState(false);
    const [attended, setAttended] = useState(false);
    const [all, setAll] = useState(true);
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState(false);
    const [adminListOfUsers, setAdminListOfUsers] = useState([]);
    const [createSession, setCreateSession] = useState(false);


    const changeTab = (e, tab) => {
        setLoading(false);
        const parent = e.target.parentElement;
        const children = parent.children;
        for (let i = 0; i < children.length; i++) {
            children[i].classList.remove("sessions-header-active");
        }
        e.target.classList.add("sessions-header-active");
        switch (tab) {
            case 'all':
                setAll(true);
                setConfirmed(false);
                setUpdates(false);
                setPending(false);
                setAttended(false);
                break;
            case 'confirmed':
                setConfirmed(true);
                setUpdates(false);
                setPending(false);
                setAll(false);
                setAttended(false);
                break;
            case 'updates':
                setConfirmed(false);
                setUpdates(true);
                setPending(false);
                setAll(false);
                setAttended(false);
                break;
            case 'requests':
                setConfirmed(false);
                setUpdates(false);
                setPending(true);
                setAll(false);
                setAttended(false);
                break;
            case 'attended':
                setConfirmed(false);
                setUpdates(false);
                setPending(false);
                setAll(false);
                setAttended(true);
            default:
                break;
        }
    }

    //get all the document ids from the collection
    const events = collection(db, "events");

    const pendingQ = query(events, where("status", "==", "pending"));
    const confirmedQ = query(events, where("status", "==", "confirmed"));
    const updatedQ = query(events, where("status", "==", "updated"));
    const attendedQ = query(events, where("status", "==", "attended"));
    const adminQ = query(collection(db, "events"), orderBy("utcTime", "asc"));
    const [pendingData] = useCollectionData(pendingQ, { idField: 'id' });
    const [confirmedData] = useCollectionData(confirmedQ, { idField: 'id' });
    const [updatesData] = useCollectionData(updatedQ, { idField: 'id' });
    const [attendedData] = useCollectionData(attendedQ, { idField: 'id' });
    const [allData] = useCollectionData(adminQ, { idField: 'id' });

    return (
        <div className="platform-right-container">
            <PlatformHeader handleAlerts={props.triggerAlertBox} tab="Sessions"></PlatformHeader>
            <div className="sessions-container">
                <div className="sessions-container-header">
                    <button onClick={(e) => changeTab(e,"all")} className="sessions-header-active">All</button>
                    <button onClick={(e) => changeTab(e,"confirmed")}>Confirmed</button>
                    <button onClick={(e) => changeTab(e,"updates")}>Updates</button>
                    <button onClick={(e) => changeTab(e,"requests")}>Requests</button>
                    <button onClick={(e) => changeTab(e,"attended")}>Attended</button>
                </div>
                <button className="create-session-button" onClick={()=>setCreateSession(true)}>Create Session</button>

                {
                    loading ? (
                        <div className="sessions-loader">
                            <Loader></Loader>
                        </div>
                    ) : (
                        <div className="sessions">
                        {
                            all && allData &&(
                                <>
                                    {
                                    allData.length === 0 ?
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            allData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={'admin'} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                    ></Event>
                                                )}
                                    
                                            )}
                                        </>
                                    )
                                    }
                                </>
                            )
                        }
                        {
                            confirmed && confirmedData && (
                                <>
                                    {
                                    confirmedData.length === 0 ? 
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            confirmedData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={'admin'} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                    ></Event>
                                                )}
                                        
                                            )}
                                        </>
                                    )
                                    }
                                </>
                            )
                        }
    
                        {
                            updates && updatesData && (
                                <>
                                    {   updatesData.length === 0 ?
                                    (
                                        <h1>No Updates</h1>
                                    )
                                    :
                                    (
                                        <>
    
                                            {
                                            updatesData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={'admin'} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                    ></Event>
                                                )}
                                            )}
                                </>
                                    )}
                                </>
                            )
                        }
    
                        {
                            pending && pendingData && 
                            <>
                                {
                                    pendingData.length === 0 ?
                                    (
                                        <h1>No Requests</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            pendingData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        eventData={event} 
                                                        userType={'admin'} 
                                                        type={event.type} 
                                                    ></Event>
                                                )}
                                            )}
                                        </>
                                    )}
                            </>
                        }
                            
                        {
                            attended && attendedData && (
                                <>
                                    {
                                    attendedData.length === 0 ?
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            attendedData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={'admin'} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                    ></Event>
                                                )}
                                            )}
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                    )
                }

            </div>
            
            {
                createSession && (
            
                <ScheduleSideDrawer 
                    //newSuccessfulRequest={(e) => handleSuccessfulRequest(e)} 
                    //firstConsult={props.firstConsult} 
                    event={false} 
                    onClickExit={() => setCreateSession(false)}
                    //updateEventsList={(e) => updateEvents(e)}
                    //deleteEventFromList={(e) => deleteEvent(e)}
                    date={date}
                    create={true}
                    newSuccessfulRequest={(e) => console.log(e)}
                    notes={""}
                    startTime={"14:00"}
                    endTime={"14:30"}
                    title={"title"}
                    agreementStatus={"agreementStatus"}
                    durationH={0}
                    durationM={30}
                    requester={"requester"}
                    requestee={"requestee"}
                    timeZone={"America/New_York"}
                    eventId={""}
    
                ></ScheduleSideDrawer>
                )
            }
        </div>
    )
}

export default AdminSessions;