
import './Astronaut.css'

function Astronaut1(){
    return (
        <div className="pen-content">
        <div className="container-astronaut">
          <div className="cloud cloud-1">
            <div className="part part-1"></div>
            <div className="part part-2"></div>
            <div className="part part-3"></div>
          </div>
          <div className="cloud cloud-2">
            <div className="part part-1"></div>
            <div className="part part-2"></div>
            <div className="part part-3"></div>
            <div className="part part-4"></div>
            <div className="part part-5"></div>
            <div className="part part-6"></div>
          </div>
          <div className="space">
            <div className="moon">
              <div className="crater crater-1"></div>
              <div className="crater crater-2"></div>
              <div className="crater crater-3"></div>
            </div>
            <div className="star star-1"></div>
            <div className="star star-2"></div>
            <div className="star star-3"></div>
            <div className="star star-4"></div>
            <div className="star star-5"></div>
            <div className="star star-6"></div>
            <div className="star star-7"></div>
          </div>
          <div className="astronaut-frame">
            <div className="astronaut">
              <div className="backpack"></div>
              <div className="body">
                <div className="shadow"></div>
                <div className="strap left"></div>
                <div className="strap right"></div>
                <div className="chest"></div>
              </div>
              <div className="ear left">
                <div className="antenna"></div>
              </div>
              <div className="ear right"></div>
              <div className="head">
                <div className="face">
                  <div className="eye left"></div>
                  <div className="eye right"></div>
                  <div className="mouth"></div>
                  <div className="cheek left"></div>
                  <div className="cheek right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Astronaut1