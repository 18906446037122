import './Loader.css'

function Loader(props) {

    return (
        <div className="loader-container">
        <div className="square one"></div>
        <div className="square two"></div>
        <div className="square three"></div>
        <div className="square two"></div>
        <div className="square three"></div>
        <div className="square four"></div>
        <div className="square three"></div>
        <div className="square four"></div>
        <div className="square five"></div>
        </div>
    )
}

export default Loader