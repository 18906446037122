import Rocket2 from '../../Components/Rocket2'
import './FirstTime.css'
import { useState } from 'react'
import {port, site} from '../EnvVariables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {addSecondaryEmail, auth, db} from '../../Firebase';
import { doc, setDoc, collection } from "firebase/firestore"; 
import { sendEmail } from '../ApiFunctions';

function FirstTime(props) {
    //get the user's id
    console.log(props.tutorStatus   )
    const user = auth.currentUser;
    const [parentEmail, setParentEmail] = useState("");
    const [studentEmail, setStudentEmail] = useState(null);
    const [grade, setGrade] = useState(null);
    const [studentSubject, setStudentSubject] = useState([]);
    const [tutorSubject, setTutorSubject] = useState(null);
    const [tutorInfo, setTutorInfo] = useState("");
    const [tutorApplied, setTutorApplied] = useState(props.tutorStatus);

    const [form, setForm] = useState(props.tutorStatus);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const handlePointerMove = (e)=>{
        const { currentTarget: el, clientX: x, clientY: y } = e;
        const { top: t, left: l, width: w, height: h } = el.getBoundingClientRect();
        el.style.setProperty('--posX',  x-l-w/2);
        el.style.setProperty('--posY',  y-t-h/2);
    } 

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const user = auth.currentUser;
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();


        if (form === "tutor") {
            if (tutorSubject === []) {
                alert("Please select a subject.")
                return
            }
            if (tutorInfo === "") {
                alert("Please enter some information about yourself.")
                return
            }
            setTutorApplied(true)
            const tutorData = {
                firstName: capitalizeFirstLetter(firstName),
                lastName: capitalizeFirstLetter(lastName),
                phoneNumber: phoneNumber,
                email: user.email,
                uid: user.uid,
                subject: tutorSubject,
                info: tutorInfo,
                userType: "pending-tutor",
                date: new Date()
            }
            setDoc(doc(db, "users", user.uid), tutorData)
            sendEmail(user.email, false, firstName, lastName, form)
            return
        }


        let validEmail = validEmailCheck(parentEmail)
        if ((parentEmail === "") && (!validEmail) && (grade <= 12)) {
            alert("Please enter a valid parent google email.")
            return
        }

        if ((form === "student") ){
            if (grade === null || studentSubject === null) {
                alert("Please select a grade and a area of help.")
                return 
            }
            if (grade === null || studentSubject === null) {
                alert("Please select a grade and a area of help.")
                return 
            }
        }
         
        if ((form === "parent")) {
            if (grade === null || studentSubject === []) {
                alert("Please select a grade and a area of help.")
                return 
            }
        }


        if (firstName === "") {
            alert("Please enter your first name.")
            return
        }
        if (lastName === "") {
            alert("Please enter your last name.")
            return
        }
        if (phoneNumber === "") {
            alert("Please enter your phone number.")
            return
        }
        const data = {
            firstName: capitalizeFirstLetter(firstName),
            lastName: capitalizeFirstLetter(lastName),
            phoneNumber: phoneNumber,
            email: user.email,
            uid: user.uid,
            joined: date,
            userType: form,
            grade: grade,
            subject: studentSubject,
            customer_id: null,
            payment_method_id: null,
            card_last4: null,
            card_brand: null,
            card_exp_year: null,
            parentEmail: parentEmail, //secondary email, can be child email on sign In
            parentUid: null,
            level: 0,
            tutors: 0,
            totalSessions: 0,
            ascendScore: 100,
            minutesBillable: 0,
        }
        //if the user is a tutor, add the tutor info
        if (form === "tutor") {
            data.tutorInfo = tutorInfo;
            data.tutorSubject = tutorSubject;
            data.studentUids = [];
        }
        //await the response of setDoc(doc(db, "users", user.uid), data); and once its done, redirect to the dashboard
        
        console.log(data)
        
        setDoc(doc(db, "users", user.uid), data)
        .then(() => {
            sendEmail(user.email, parentEmail, firstName, lastName, form)
            if (parentEmail !== "" && parentEmail !== user.email) {
                addSecondaryEmail(user.uid, parentEmail, user.email, firstName)
            }
        })
        .then(() => {
            props.student()
        })
        
            
    }

    const handleNewUser = (userType) => {
        setForm(userType)
        setStudentEmail(null)
    }

    const FormButtonClicked = (e, value) => {
        e.preventDefault();
        //add class "form-button-selected" to the element
        //remove class "form-button-selected" from the other elements in the parent
        const parent = e.target.parentElement;
        const children = parent.children;
        //if value is already in studentSubject, remove the form-button-selected class from it
        if (studentSubject.includes(value)) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].value === value) {
                    children[i].classList.remove("form-button-selected")
                }
            }
            //remove the value from studentSubject
            setStudentSubject(studentSubject.filter(item => item !== value))
        }
        e.target.classList.add("form-button-selected")
        setStudentSubject(studentSubject => [...studentSubject, value])
    }


    const studentFormSelect = (e) => {
        setGrade(e.target.value)
    }


    const validEmailCheck = (email) => {
        let validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        if (!validEmail) {
            return false
        }
        else {
            return true
        }
    }

    
    function capitalizeFirstLetter(string) {

        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return (
        <div className="first-time-container" onPointerMove={handlePointerMove}>
            <div className="star_container">
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
            </div> 
            <div className="first-time-header">
                <h1>Ascend Academy</h1>
            </div>
            {!form &&(
                <>
                <h1>Welcome aboard {user.displayName.split(' ')[0]}!</h1>
                <h2>What are you?</h2>
                </>
            )
            }
            
            {
                !form && (
                    <section>

                    <div className={"choice-button button-s"} onClick={() => handleNewUser("student")}>
                        <h3>Student</h3>
                    </div>
    
                    <div className={"choice-button button-p"} onClick={() => handleNewUser("parent")}>
                        <h3>Parent</h3>
                    </div>
    
                    <div className={"choice-button button-t"} onClick={() => handleNewUser("tutor")}>
                        <h3>Tutor</h3>
                    </div>
                </section>
                )
            }

            {
                form && (
                    <div className="form">
                        {
                            !tutorApplied && (
                                <FontAwesomeIcon icon={faArrowLeft} onClick={() => setForm(false)}/>
                            )
                        }
                        
                        <form onSubmit={handleFormSubmit}>
                            {
                                tutorApplied ? (
                                    <h2>Thank you for applying to be a tutor!</h2>
                                ) : (
                                    <h2>Let's get to know you!</h2>
                                )
                            }
                            

  


                            {
                                form === "student" && (
                                    <>
                                    <div>
                                        <span>
                                            <label>Student First Name</label>
                                            <input type="text" placeholder="John" onChange={(e) => setFirstName(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>Student Last Name</label>
                                            <input type="text" placeholder="Doe" onChange={(e) => setLastName(e.target.value)}></input>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <label>Phone Number</label>
                                            <input type="text" placeholder="123-456-7890" onChange={(e) => setPhoneNumber(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>What grade are you in?</label>
                                            <select name="grade" id="grade" onChange={studentFormSelect}>
                                                <option defaultValue="" disabled selected>Select your grade</option>
                                                <option defaultValue="1">1</option>
                                                <option defaultValue="2">2</option>
                                                <option defaultValue="3">3</option>
                                                <option defaultValue="4">4</option>
                                                <option defaultValue="5">5</option>
                                                <option defaultValue="6">6</option>
                                                <option defaultValue="7">7</option>
                                                <option defaultValue="8">8</option>
                                                <option defaultValue="9">9</option>
                                                <option defaultValue="10">10</option>
                                                <option defaultValue="11">11</option>
                                                <option defaultValue="12">12</option>
                                                <option defaultValue="freshman">College Freshman</option>
                                                <option defaultValue="sophomore">College Sophomore</option>
                                                <option defaultValue="junior">College Junior</option>
                                                <option defaultValue="senior">College Senior</option>
                                                <option defaultValue="grad">Graduate Student</option>
                                            </select>
                                        </span>
                                    </div>
                                    
                                    <label>What do you need help in?</label>
                                    <div className="student-form-buttons">
                                        <button onClick={(e) => FormButtonClicked(e,"academics")}>Academic Tutoring</button>
                                        <button onClick={(e) => FormButtonClicked(e, "tests")}>SAT/ACT Test Prep</button>
                                        <button onClick={(e) => FormButtonClicked(e, "college")}>College Admissions</button>
                                        <button onClick={(e) => FormButtonClicked(e, "other")}>Other Mentorship</button>
                                    </div>

                                    {
                                        grade && (grade <= 12) && (
                                            <>
                                            <label>Parent Email (Google Email)</label>
                                            <div className="additional-email">
                                                <input type="text" placeholder='mom@gmail.com' onChange={(e) => setParentEmail(e.target.value)}></input>
                                                <span></span>
                                                <p>An email will be sent to invite to the platform.</p>
                                            </div>
                                            </>
                                        )
                                    }
                                    
                                    
                                    
                                    </>
                                )
                            }

                            {
                                form === "parent" && (
                                    <>
                                    <div>
                                        <span>
                                            <label>Student First Name</label>
                                            <input type="text" placeholder="John" onChange={(e) => setFirstName(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>Student Last Name</label>
                                            <input type="text" placeholder="Doe" onChange={(e) => setLastName(e.target.value)}></input>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <label>Student Email (Google Email)</label>
                                            <input type="text" placeholder='student@gmail.com' onChange={(e) => setParentEmail(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>What grade is your child in?</label>
                                            <select name="grade" id="grade" onChange={studentFormSelect}>
                                        <option defaultValue="" disabled selected>Select your grade</option>
                                        <option defaultValue="1">1</option>
                                        <option defaultValue="2">2</option>
                                        <option defaultValue="3">3</option>
                                        <option defaultValue="4">4</option>
                                        <option defaultValue="5">5</option>
                                        <option defaultValue="6">6</option>
                                        <option defaultValue="7">7</option>
                                        <option defaultValue="8">8</option>
                                        <option defaultValue="9">9</option>
                                        <option defaultValue="10">10</option>
                                        <option defaultValue="11">11</option>
                                        <option defaultValue="12">12</option>
                                        <option defaultValue="freshman">College Freshman</option>
                                        <option defaultValue="sophomore">College Sophomore</option>
                                        <option defaultValue="junior">College Junior</option>
                                        <option defaultValue="senior">College Senior</option>
                                        <option defaultValue="grad">Graduate Student</option>
                                            </select>
                                        </span>
                                        
                                    </div>
                                    <div>
                                        <span>
                                            <label>Phone Number</label>
                                            <input type="text" placeholder='123-456-7890' onChange={(e) => setPhoneNumber(e.target.value)}></input>
                                        </span>
                                    </div>
                                    <label>What do they need help in?</label>
                                    <div className="student-form-buttons">
                                        <button onClick={(e) => FormButtonClicked(e,"academics")}>Academic Tutoring</button>
                                        <button onClick={(e) => FormButtonClicked(e, "tests")}>SAT/ACT Test Prep</button>
                                        <button onClick={(e) => FormButtonClicked(e, "college")}>College Admissions</button>
                                        <button onClick={(e) => FormButtonClicked(e, "other")}>Other Mentorship</button>
                                    </div>
                                    
                                    </>
                                )
                            }
                            {
                                form === "tutor" && (
                                    <>
                                    {
                                        tutorApplied ? (
                                            <div className="tutor-applied">
                                                <label>We will be in contact with you shortly through email and phone.</label>
                                            </div>
                                        )
                                        :
                                        (
                                    <>
                                    <div>
                                        <span>
                                            <label>Tutor First Name</label>
                                            <input type="text" placeholder="John" onChange={(e) => setFirstName(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>Tutor Last Name</label>
                                            <input type="text" placeholder="Doe" onChange={(e) => setLastName(e.target.value)}></input>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            <label>Phone Number</label>
                                            <input type="text" placeholder='123-456-7890' onChange={(e) => setPhoneNumber(e.target.value)}></input>
                                        </span>
                                        <span>
                                            <label>What do you teach?</label>
                                            <select name="subject" id="subject" onChange={(e) => setTutorSubject(e.target.value)}>
                                                <option defaultValue="" disabled selected>Select your subject</option>
                                                <option defaultValue="math">Math</option>
                                                <option defaultValue="science">Science</option>
                                                <option defaultValue="english">English</option>
                                                <option defaultValue="history">History</option>
                                                <option defaultValue="coding">Coding</option>
                                                <option defaultValue="other">Other</option>
                                            </select>
                                        </span>
                                    </div>
                                    <label>Anything you would like to share with us?</label>
                                    <textarea placeholder='I love anime!' onChange={(e) => setTutorInfo(e.target.value)}></textarea>
                                    </>
                                        )
                                    }
                                    </>
                                
                                )
                            }
                            {
                                !tutorApplied && (
                                    <button className="form-submit-button" type="submit">Submit</button>
                                )
                            }
                            
                        </form>
                    </div>
                )
            }
                


        </div>
    )
}

export default FirstTime
