import './LandingPage.css'
import { Parallax, ParallaxLayer} from '@react-spring/parallax'
import React, { useEffect, useRef, useState } from 'react';
import Container from '../Components/Container';
import Header from '../Components/Header'
import DescriptionBox from '../Components/DescriptionBox';
import Rocket1 from '../Components/Rocket1';
import Astronaut1 from '../Components/Astronaut1';
import Astronaut2 from '../Components/Astronaut2';
import Rocket2 from '../Components/Rocket2';
import Cards from '../Components/Cards'
import Tutor from '../Components/Tutor'
import TutorSection from '../Components/TutorSection'
import ReviewSection from '../Components/ReviewSection';
import CardPopup from '../Components/CardPopup';
import Pricing from '../Components/Pricing';
import JoinUs from '../Components/JoinUs';
import SignInPopup from '../Components/SignInPopup';
import Footer from '../Components/Footer';
import { signInWithGoogle } from '../Firebase'
import { useNavigate } from "react-router-dom";

function LandingPage(props){
    const navigate = useNavigate();
    const [cardPopup, setCardPopup] = useState(false)
    const [signInPopUp, setSignInPopUp] = useState(false)
    const [signUpPopUp, setSignUpPopUp] = useState(false)
    const ref = useRef();
  
    const [titleWord, setTitleWord] = useState('Academics')
    const handlePointerMove = (e)=>{
      const { currentTarget: el, clientX: x, clientY: y } = e;
      const { top: t, left: l, width: w, height: h } = el.getBoundingClientRect();
      el.style.setProperty('--posX',  x-l-w/2);
      el.style.setProperty('--posY',  y-t-h/2);
    }


      
    const triggerCardPopUp = (e) => {
      setCardPopup(e)
    }
    const closeCardPopup = (e) => {
        setCardPopup(false)
    }

    const triggerSignUp = (e) => {
        setSignUpPopUp(true)
    }

    const triggerSignIn = (e) => {
        setSignInPopUp(true)
    }
    const closeSignUp = (e) => {
        setSignUpPopUp(false)
    }
    const closeSignIn = (e) => {
        setSignInPopUp(false)
    }
    const handleGoogleSignIn = async () => {
        try {
            let response = await signInWithGoogle();
            navigate("/app");
            
        } catch (error) {
            alert(error);
            
        }
    }



    return (

    <div className="parent_container" onPointerMove={handlePointerMove}>
        <div className="star_container">
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
        </div> 
        <Parallax pages={8.9} ref={ref}>
            <Header 
                mission={() => ref.current.scrollTo(1)} 
                tutors={() => ref.current.scrollTo(6.15)}
                reviews={() => ref.current.scrollTo(7)}
                pricing={() => ref.current.scrollTo(7.6)} 
                onSignIn={handleGoogleSignIn} 
                onSignUp={handleGoogleSignIn} 
                offset={0} 
                factor={1} 
                speed={1}  
                sticky={{start: 0, end: 0.1}}></Header>
        
            {/*
            <ParallaxLayer offset={0} factor={1} speed={1}>
            <div className='wrapper'>
            <TitleBox word={titleWord}></TitleBox>
            </div>
            </ParallaxLayer>
            */}

            <ParallaxLayer offset={0} factor={1} speed={10}>
            <div className='rocket-wrapper'>
                <Rocket1></Rocket1>
            </div>
            </ParallaxLayer>


            <ParallaxLayer offset={0} factor={1} speed={15}  sticky={{start: 0, end: 0.1}}>
                <div className='wrapper'>
                    <DescriptionBox handleClick={handleGoogleSignIn}></DescriptionBox>
                </div>
            </ParallaxLayer>
            
            <ParallaxLayer offset={0.25} factor={.5} speed={1}>
            <div className='wrapper'>
                <Container></Container>
            </div>
            </ParallaxLayer>

        
            <ParallaxLayer ref={ref} offset={1} factor={1} speed={1} sticky={{start: 1, end: 1.2}}>
            <div className='right-content'>
                <div className="container-blind">
                <h1>Tell us about your student</h1>
                <Astronaut1></Astronaut1>
                <p>Sign up for a free consultation. We love chatting with kids and parents to see how we can best help achieve their goals.</p>

                </div>
            </div>
            </ParallaxLayer>


            <ParallaxLayer offset={2} factor={1} speed={1} sticky={{start: 2.3, end: 2.7}}>
            <div className='right-content'>
            <div className="container-blind">
                <h1>Identify your needs and goals</h1>
                <Astronaut2></Astronaut2>
                <p>Does your student need help with current coursework or preparing for future coursework? Standardized testing? Developing study tactics and time management? College application essays? Career development? A passion project? We want to know!
                </p>
            </div>
            </div>
            </ParallaxLayer>

            <ParallaxLayer offset={3} factor={1} speed={1} sticky={{start: 3.8, end: 4}}>
            <div className='right-content'>
                <div className='container-blind'>
                <h1>Meet our Mentors</h1>
                <Tutor></Tutor>
                <p>After understanding how we can provide you the most value, we’ll personally match you with a mentor that best fits your preferences, interests and goals! </p>

                </div>
            </div>
            </ParallaxLayer>

            <ParallaxLayer offset={4} factor={1} speed={1} sticky={{start: 5.1, end: 5.3}}>
            <div className='right-content'>
                <div className='container-blind'>
                <h1>Start to Ascend!</h1>
                <Rocket2></Rocket2>
                <p>We’ll introduce you to your mentor, and sessions will begin on a custom schedule that works best for you! You can expect notes from your mentor after every session!
                    
                </p>

                </div>
            </div>
            </ParallaxLayer>

            <ParallaxLayer offset={1} factor={1} speed={1} sticky={{start: 1, end: 5.3}}>
            <div className='left-content'>
                <div className="container-blind">
                
                <Cards onCardClick={triggerCardPopUp}></Cards>
                
                </div>
            </div>
            </ParallaxLayer>

            <ParallaxLayer offset={6.3} factor={1} speed={.5}>
            <TutorSection></TutorSection>
            </ParallaxLayer>

            {/*
            <ParallaxLayer offset={7.1} factor={1} speed={.2}>
                <ReviewSection></ReviewSection>
            </ParallaxLayer>
            */}
            

            <ParallaxLayer offset={7} factor={1} speed={.17}>
                <Pricing></Pricing>
            </ParallaxLayer>

            <ParallaxLayer offset={7.95} factor={.7} speed={.05}>
                <JoinUs platformClick={handleGoogleSignIn}></JoinUs>
            </ParallaxLayer>

            <ParallaxLayer offset={8.6} factor={.3} speed={.1}>
                <Footer></Footer>
            </ParallaxLayer>
            
        </Parallax>
        
        {cardPopup && (
            <CardPopup type={cardPopup} onExit={closeCardPopup}></CardPopup>
        )
        }

        {signUpPopUp && (
            <SignInPopup type={"sign-up"} onExit={closeSignUp}></SignInPopup>
        )
        }
        {signInPopUp && (
            <SignInPopup type={"sign-in"} onExit={closeSignIn}></SignInPopup>
        )
        }

    </div>)
}


export default LandingPage