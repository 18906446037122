import './Schedule.css';
import PlatformHeader from '../PlatformHeader';
import Notification from '../Notification';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import ScheduleSideDrawer from '../ScheduleSideDrawer';
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHand} from '@fortawesome/free-solid-svg-icons';
import { site, port } from '../../EnvVariables';
import {auth, db, userCheck} from '../../../Firebase';
import Loader from '../Loader';
import {query, collection, where } from "firebase/firestore"; 
import { useCollectionData } from 'react-firebase-hooks/firestore';


  


function StudentSchedule(props) {
    const user = auth.currentUser;
    const [studentName, setStudentName] = useState("");
    const [studentUid, setStudentUid] = useState(user.uid);
    const [showSideDrawer, setShowSideDrawer] = useState(props.initialTime);
    const [eventStatus, setEventStatus] = useState(props.eventStatus);
    const [calendarLoad, setCalendarLoad] = useState(true);
    const [date, setDate] = useState(props.initialEvent);
    const [notes, setNotes] = useState(props.initialNotes);
    const [time, setTime] = useState(null);
    const [title, setTitle] = useState(props.initialTitle);
    const [startTime, setStartTime] = useState(props.initialTime);
    const [endTime, setEndTime] = useState(props.initialEndTime);
    const [agreementStatus, setAgreementStatus] = useState(null);
    const [durationH, setDurationH] = useState(props.initialHour);
    const [durationM, setDurationM] = useState(props.initialMinute);
    const [requester, setRequester] = useState(null);
    const [requestee, setRequestee] = useState(null);   
    const [alertNum, setAlertNum] = useState(0);
    const [timeZone, setTimeZone] = useState(null);
    const [eventId, setEventId] = useState(props.eventId);
    const [notificationAlert, setNotificationAlert] = useState(false);
    const [notificationAlertMessage, setNotificationAlertMessage] = useState(null);
    const [firstConsult, setFirstConsult] = useState(true);
    
    const user_events = collection(db, 'events');
    const q = query(user_events, where("uid", "==", studentUid));

    const [events] = useCollectionData(q, { idField: 'id' });


    const handleSuccessfulRequest = () => {
        setNotificationAlert(true);
        setAlertNum(alertNum + 1);
        setNotificationAlertMessage('Session Request Sent!')

        setTimeout(() => {
            setNotificationAlert(false);
            setNotificationAlertMessage(null);
        }
        , 3000);


    }

    const updateEvents = (e) => {
        setNotificationAlert(true);
        setAlertNum(alertNum + 1);
        setNotificationAlertMessage('Session Request Updated!')

        setTimeout(() => {
            setNotificationAlert(false);
            setNotificationAlertMessage(null);
        }
        , 3000);
    }

    const deleteEvent = (e) => {
        //find the event in the events array and update it
        const index = events.findIndex(event => event.id === e.id);

        //delete the event
        const newEvents = [...events];
        newEvents.splice(index, 1);
    }

    const events_example = [//get these from the mongo
        {   title: 'event 1', 
            date: '2022-12-01',
            id: "asdasd", // use for getEventById
            start_lol: '12:00',
            end_lol: '13:00',
            time: '12:00',
            additionalInfo: "asdasd",
            timeZone: "nyc",
            hour: '1',
            minute: '2',
            uid: 'asdasdasdasdasdasd',
            requester: "student",
            requestee: "tutor",
            agreement: false,
         },

    ]

    const handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg)
        
        setDate(arg.dateStr);
        setEventStatus(false);
        setNotes("");
        setStartTime("14:00");
        setEndTime("");
        setAgreementStatus(null);
        setDurationH("");
        setDurationM("");
        setRequester("");
        setRequestee("");
        setTimeZone("");
        setShowSideDrawer(true);
    }
    const handleEventClick = (arg) => { // bind with an arrow function
        const title = arg.event.title;
        const additionalInfo = arg.event._def.extendedProps;
        console.log(additionalInfo);

        //handle the existing request
        setEventStatus(true);
        setDate(additionalInfo.titleDate);
        setNotes(additionalInfo.additionalInfo);
        setStartTime(additionalInfo.time);
        setEndTime(additionalInfo.endTimehm);
        setAgreementStatus(additionalInfo.agreement);
        setDurationH(additionalInfo.hour);
        setDurationM(additionalInfo.minute);
        setRequester(additionalInfo.requester);
        setRequestee(additionalInfo.requestee);
        setTitle(title);
        setTimeZone(additionalInfo.timeZone);
        setEventId(additionalInfo.eventId);

        setShowSideDrawer(true);


        
    }
    const closeScheduleSideDrawer = (value) => {
        setShowSideDrawer(value);
        props.unselectEvent();
    }
    useEffect(() => {
        userCheck(user.uid, user.email).then(
                    (result) => {
                        setStudentUid(result.uid);
                        setStudentName(result.firstName + " " + result.lastName);
                        if (result.level > 0) {
                            setFirstConsult(true);
                            
                        }
                        setCalendarLoad(false);
                        return result;
                    }
        );

        }, [])

    return (
        <div className="platform-right-container">
            <PlatformHeader handleAlerts={props.triggerAlertBox} alertCount={alertNum} tab="Schedule"></PlatformHeader>
            {
                notificationAlert && (

                    //get the latest event and display it here
                    
                    <Notification message={notificationAlertMessage}></Notification>
                )
            }

            {!calendarLoad && (
                <>
                        {
                !firstConsult && (
                    <div className="firstConsult-box">
                        
                        <h4>Let's introduce each other! Schedule your first chat.</h4>
                        <FontAwesomeIcon icon={faHand}></FontAwesomeIcon>
                    </div>
                )
                }
                <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            dateClick={handleDateClick}
                            initialView='dayGridMonth' //dayGridWeek
                            initialDate={props.initialEvent}
                            weekends={true}
                            events={events}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            eventBorderColor="transparent"
                />
                </>
            )}

            {calendarLoad && (
                <Loader></Loader>)}

            {
                showSideDrawer && (
                    <ScheduleSideDrawer 
                    newSuccessfulRequest={(e) => handleSuccessfulRequest(e)} 
                    firstConsult={props.firstConsult} 
                    event={eventStatus} 
                    studentUid = {studentUid}
                    onClickExit={closeScheduleSideDrawer}
                    updateEventsList={(e) => updateEvents(e)}
                    deleteEventFromList={(e) => deleteEvent(e)}
                    date={date}
                    name = {studentName}
                    notes={notes}
                    startTime={startTime}
                    endTime={endTime}
                    title={title}
                    agreementStatus={agreementStatus}
                    durationH={durationH}
                    durationM={durationM}
                    requester={requester}
                    requestee={requestee}
                    timeZone={timeZone}
                    eventId={eventId}
    
                ></ScheduleSideDrawer>
                )
            }


        </div>
    )

}

function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

export default StudentSchedule;