import './ReviewSection.css'
import ReviewCard from './ReviewCard'
import { useEffect, useState, useRef} from 'react';

import review1 from "../img/review1.jpg"
import review2 from "../img/review2.jpg"
import review3 from "../img/review3.jpg"
import review4 from "../img/review4.jpg"
import review5 from "../img/review5.jpg"
import review6 from "../img/review6.jpg"



function ReviewSection() {
  const [checked, setChecked] = useState(true);

  const quotes = [
    {from: "Nihal S., Father of Jemakisad",
    quote: `"It's hard to find the perfect tutor. Luckily, Ascend Academy made sure that my son was matched with the right mentor before we even got started. They always kept me in the loop with my son and made sure that every class was being used effectively."`},
    {from: "John C., Father of Kevin",
    quote: `"Ascend made finding a tutor quick and easy. My son Kevin has improved his grades tremendously this semester all thanks to his mentor. Would definitely recommend anyone to this program."`},
    {from: "Jeff S., Father of Justin",
    quote: `"Ascend Academy has been a great experience for my son. He has been matched with a great mentor who has helped him improve his grades and confidence. He's gone from a back of the class snoozer to a class star!"`},
    {from: "Justin N., High School Student",
    quote: `"My mentors at Ascend were able to hop on any time of the day to help me with my homework, exams, and even college prep. They've been amazing guides and role models for me to learn from. They find cool and fun ways to keep it interesting!"`},
    {from: "Shannon L., High School Student",
    quote: `"The thing I found most impressive was how dedicated my mentors were. They were available at any time of the day to help me excel in my AP classes! Every week they would write me a personalized study guide to help me prepare for my exams and track my progress and goals. It's almost like a personal trainer for my brain!"`},
    {from: "Sunny M.",
    quote: `"Ascend helped me get into my dream school... Harvard University! Tutoring me to stay ahead of my classes while mentoring me for college, Ascend Academy has been a pivitol part of my education where schools have fallen short!"`},

  ]

  return(
          <div className="carousel">
    <ul className="slides">
      <input type="radio" name="radio-buttons" id="img-1"  checked={checked}/>
      <ReviewCard reviewNum={review1} num={1} quote={quotes[0].quote} from={quotes[0].from}></ReviewCard>
      <input type="radio" name="radio-buttons" id="img-2"  checked={checked}/>
      <ReviewCard reviewNum={review2} num={2} quote={quotes[1].quote} from={quotes[1].from}></ReviewCard>
      <input type="radio" name="radio-buttons" id="img-3" checked={checked}/>
      <ReviewCard reviewNum={review3} num={3} quote={quotes[2].quote} from={quotes[2].from}></ReviewCard>
      <input type="radio" name="radio-buttons" id="img-4" checked={checked}/>
      <ReviewCard reviewNum={review4} num={4} quote={quotes[3].quote} from={quotes[3].from}></ReviewCard>
      <input type="radio" name="radio-buttons" id="img-5" checked={checked}/>
      <ReviewCard reviewNum={review5} num={5} quote={quotes[4].quote} from={quotes[4].from}></ReviewCard>
      <input type="radio" name="radio-buttons" id="img-6" checked={checked}/>
      <ReviewCard reviewNum={review6} num={6} quote={quotes[5].quote} from={quotes[5].from}></ReviewCard>


      <div className="carousel-dots">
        <label htmlFor="img-1" className="carousel-dot" id="img-dot-1"></label>
        <label htmlFor="img-2" className="carousel-dot" id="img-dot-2"></label>
        <label htmlFor="img-3" className="carousel-dot" id="img-dot-3"></label>
        <label htmlFor="img-4" className="carousel-dot" id="img-dot-4"></label>
        <label htmlFor="img-5" className="carousel-dot" id="img-dot-5"></label>
        <label htmlFor="img-6" className="carousel-dot" id="img-dot-6"></label>
      </div>
    </ul>
          </div>
    )
}

export default ReviewSection