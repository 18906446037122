import './Event.css'
import {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faVideoCamera, faCheck, faPenClip, faTrash, faCalendarCheck,faX} from "@fortawesome/free-solid-svg-icons";
import {site, port} from "../EnvVariables"
import { auth, db, getEvent } from '../../Firebase';
import { aC } from '@fullcalendar/core/internal-common';
import { doc, deleteDoc, setDoc, query, collection, updateDoc, increment, FieldValue } from "firebase/firestore"; 
import { datadogLogs } from '@datadog/browser-logs';


function Event(props) {
    const [assignConfirm, setAssignConfirm] = useState(false);
    const [assigned, setAssigned] = useState(null);
    const [checkMark, setCheckMark] = useState(false);
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const [markAttendedBox, setMarkAttendedBox] = useState(false);
    const [markAttendedText, setMarkAttendedText] = useState(props.eventData['attendedNotes']);
    const [attendedStatus, setAttendedStatus] = useState(false);
        //grab the time and covert it to a 12 hour clock
    const timeAdjust = (time) => {
            let hour = time.split(":")[0]
            let minute = time.split(":")[1]
            let ampm = "AM"
            if (hour > 12) {
                hour = hour - 12
                ampm = "PM"
            }
            return hour + ":" + minute + ampm
        }

    const handleAttendedStatus = (e, status) => {   
            setAttendedStatus(status);
            //add class "selected-attended" to the button
            //remove class "selected-attended" from the other button using the e element

            const buttons = e.target.parentElement.children;
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].classList.remove("selected-attended");

            }
            e.target.classList.add("selected-attended");

            

        }

    const saveAttended = () => {
            const hoursToMin = parseInt(props.eventData['hour']) * 60;
            const totalMin = hoursToMin + parseInt(props.eventData['minute']);

            if (attendedStatus === false) {
                alert("Please select a status");
                return;
            }
            if (props.eventData['status'] !== "attended") {
                updateDoc(doc(db, "users", props.eventData.uid), {
                    minutesBillable: increment(totalMin),
                    totalSessions: increment(1)
                })
            }
            updateDoc(doc(db, "events", props.eventData.eventId), {
                status:attendedStatus,
                attendedNotes: markAttendedText,
                classNames: ['session-attended'],
                title: "Session Attended"
            })
            setMarkAttendedBox(false);

        }

    const convertDate = (date) => {
            //convert 2023-01-19 to Sat Jan 07 2023 15:58:05 GMT-0800 (Pacific Standard Time)
            const dateArray = date.split('-');
            const arrayOfMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            const month = arrayOfMonths[parseInt(dateArray[1]) - 1];
            const newDate = new Date(`${month} ${dateArray[2]}, ${dateArray[0]} 00:00:00`);
            return newDate.toDateString();

        }

    const acceptEventRequest = (eventId, studentUid) => {
            const eventData = getEvent(eventId);
            eventData.then((data) => {
                console.log(data)
                if (data.status === "error") {
                    alert("There was an error accepting the request")
                    return
                }

                fetch(`${site}${port}/api/v1/acceptEvent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data:data})

            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.status === false) {
                    alert("There was an error accepting the request")
                    datadogLogs.logger.error(data)
                    return
                } else {
                
                    //update the event doc with zoomLink and zoomPassword
                    updateDoc(doc(db, "events", eventId), {
                        assignedTutor: assigned,
                        status: "confirmed",
                        classNames: ['request-confirmed'],
                        zoomLink: data.zoomLink,
                        zoomPassword: data.zoomPassword
                    })
                    datadogLogs.logger.info({assignedTutor: assigned,
                        status: "confirmed",
                        classNames: ['request-confirmed'],
                        zoomLink: data.zoomLink,
                        zoomPassword: data.zoomPassword})

                    setAssignConfirm(false)
                }
            }
            )
            })

        }

    //create something to update the title when confirmed and removed

    const deleteRequest = (eventId) => {
            const uid = auth.currentUser.uid;
            //delete the document with uid = uid in the events collection
            const response = deleteDoc(doc(db, "events", eventId));
            setCancelConfirm(false)
            datadogLogs.logger.info("Event deleted", {eventId: eventId, uid: uid})

        }

    const handleMarkAttended = (eventId) => {
            setMarkAttendedBox(true)
            datadogLogs.logger.info("Attendance Marked eventId: " + eventId)

        }

    const assignSelect = (e) => {
            if (e.target.value !== "Unassigned") {
                //show checkmark
                setCheckMark(true)

            }
            setAssigned(e.target.value)
            datadogLogs.logger.info("Tutor assigned: " + e.target.value)
        }
        
    return (

            <div className="student-profile-event">
                <div className="event-container">
                    <div className={"student-profile-event-date " + props.eventData['status']} >
                        <h2>{timeAdjust(props.eventData['time'])} - {timeAdjust(props.eventData["endTimehm"])}</h2>
                        <h3>{convertDate(props.eventData['date'])}</h3>
                        {
                                props.eventData['status'] ==="confirmed" && (
                                    <span className="event-confirmed">Confirmed</span>
                                )
                            }
                            {
                                props.eventData['status'] ==="pending" && (
                                    <span className="event-pending">Pending</span>
                                )
                            }
                            {
                                props.eventData['status'] === "updated" && (
                                    <span className="event-updated">Updated</span>
                                )
                            }
                            {
                                props.eventData['status'] === "attended" && (
                                    <span className="event-attended">Attended</span>
                                )
                            }
                        
                    </div>
                    <div className="student-profile-event-info">
                        <div>
                            <h4>
                                {
                                    (props.userType === "tutor" || props.userType === "admin") && (
                                        <span>{props.eventData['studentName']} - </span>
                                    )
                                }
                                {props.eventData['title']}
                            </h4>
                            {
                                props.eventData['status'] === "pending" ? (
                                    <p>{props.eventData['additionalInfo']}</p>
                                )
                                :
                                (
                                    <p><b>Tutoring Session with {props.eventData['assignedTutor']}</b> | {props.eventData['additionalInfo']}</p>
                                )
                            }
                            
                            <p>{props.eventData['attendedNotes']}</p>
                        </div>
                        
                        
                        </div>
                    <div className="session-event-buttons">

                            {
                                cancelConfirm && (
                                    <div className="cancel-confirm">
                                        <p>Are you sure you want to cancel this session?</p>
                                        <button onClick={() => deleteRequest(props.eventData['eventId'])} className="cancel-confirm-yes">Yes</button>
                                        <button onClick={() => setCancelConfirm(false)} className="cancel-confirm-no">No</button>
                                    </div>
                                )
                            }
                            {
                                props.eventData['status'] !== "pending" && props.eventData['status'] !== "attended" && !cancelConfirm && 
                                <button className="zoom-button" onClick={() => window.open(props.eventData["zoomLink"])} >
                                    <FontAwesomeIcon icon={faVideoCamera} />
                                    <span>Go to Zoom</span>
                                </button>
                        

                            }

                            {
                                (props.userType === "tutor" || props.userType === "admin") && props.eventData['status'] == "pending" && !cancelConfirm && !assignConfirm && (
                                    <button onClick={() => setAssignConfirm(true)} className="zoom-confirm">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <span>Accept Request</span>
                                    </button>
                                )

                                
                            }
                            {
                                (props.userType === "tutor" || props.userType === "admin") && props.eventData['status'] !== "pending" && !cancelConfirm &&  (
                                    <button onClick={() => handleMarkAttended(props.eventData['eventId'])} className="mark-attended">
                                        <FontAwesomeIcon icon={faCalendarCheck}/>
                                        <span>Mark Attended</span>
                                    </button>
                                )
                            }
                            {
                                !cancelConfirm && !assignConfirm && (
                                    <>
                                    {
                                        (props.userType === "student") && (props.eventData['status'] !== "attended") && (
                                            <button onClick={() => props.handleReschedule(props.eventData)} className="zoom-resched">
                                                <FontAwesomeIcon icon={faPenClip} />
                                                <span>Reschedule</span>
                                            </button>
                                        )
                                    }
                                    {
                                        props.eventData['status'] !== "attended" && (
                                            <button onClick={() => setCancelConfirm(true)} className="zoom-remove">
                                                <FontAwesomeIcon icon={faTrash} />
                                                <span>Delete Event</span>
                                            </button>
                                        )
                                    }
                                    
                                    </>
                                )
                            }
                            {
                                (props.userType === "tutor" || props.userType === "admin") && assignConfirm && !cancelConfirm && (
                                    <div className="assign-box">
                                        <section>
                                            <h3 style={{whiteSpace:'nowrap'}}>Assign to:</h3>
                                            <select onChange={assignSelect}>
                                                <option value="Unassigned" >Unassigned</option>
                                                <option value="David">David</option>
                                                <option value="Thomas">Thomas</option>
                                                <option value="Edward">Edward</option>
                                                <option value="Amy">Amy</option>
                                                <option value="Warren">Warren</option>

                                            </select>
                                        </section>
                                        
                                        {
                                            checkMark && (
                                                <FontAwesomeIcon icon={faCheck} onClick={() => acceptEventRequest(props.eventData['eventId'], props.eventData['uid'])}/>
                                            )
                                        }
                                        <FontAwesomeIcon icon={faX} onClick={() => setAssignConfirm(false)}/>
                                    </div>
                                )
                            }

                    </div>
                </div>
                {
                    markAttendedBox && (
                        <div className="mark-attended-box">
                            <div className="mark-attended-box-buttons">
                                <button onClick={(e) => handleAttendedStatus(e, "attended")}className="attended">Attended</button>
                                <button onClick={(e) => handleAttendedStatus(e, "missed")} className="missed">Missed</button>
                            </div>
                            <div className="mark-attended-box-text">
                                <h5>Session Recap [{attendedStatus}]</h5>
                                <textarea
                                    value={markAttendedText}
                                    placeholder="Write a short recap of the session here. This will be visible to the student and parent."
                                    onChange={(e) => setMarkAttendedText(e.target.value)}
                                ></textarea>
                                <div>
                                    <button onClick={() => setMarkAttendedBox(false)}>Cancel</button>
                                    <button onClick={saveAttended}>Save</button>
                                </div>
                            </div>
                        </div>
                    )

                }
            </div>
        )
    }

export default Event
