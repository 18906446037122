import "./Progress.css";
import { auth, db, getUser, levelUp, userCheck} from "../../../Firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHeadset, faVideoCamera, faCalendarDays, faChalkboardUser, faChartLine, faRocket, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import PlatformHeader from "../PlatformHeader";
import { useEffect, useState} from "react";
import Event from "../Event";
import { site, port } from '../../EnvVariables';

import { createAvatar } from '@dicebear/core';
import * as style from '@dicebear/bottts-neutral';

import { doc, setDoc, query, collection, where, orderBy, Unsubscribe } from "firebase/firestore"; 
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import Loader from "../Loader";

function StudentProgress(props) {
    //grab the progress state from the props and set the progress bar to that level
    const user = auth.currentUser;
    const [studentUid, setStudentUid] = useState(user.uid);
    const [firstName, setFirstName] = useState("");
    const [alertNum, setAlertNum] = useState(0);
    const [progressLevel, setProgressLevel] = useState(0);
    const [tutors, setTutors] = useState(0);
    const [totalSessions, setTotalSessions] = useState(0);
    const [ascendScore, setAscendScore] = useState(0);
    const [userType, setUserType] = useState("");
    const [avatar, setAvatar] = useState("");
    const [load, setLoad] = useState(true);
    //const [pending, setPending] = useState(false);

    const [pointer, setPointer] = useState(0); //props what the current user is at 
    //called from mongo to check what progress the user is at, for now at 1
    const users = collection(db, "users");
    const events = collection(db, "events");
    const q = query(users, where("uid", "==", user.uid));

    const [value, loading, error] = useDocument(
        doc(db, "users", user.uid),
        {
        snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    //multiple where queries
    //if parent, grab the other students uid

    const pendingQ = query(events, where("status", "==", "pending"), where("uid", "==", studentUid));
    const confirmedQ = query(events, where("status", "==", "confirmed"), where("uid", "==", studentUid));
    const attendedQ = query(events, where("status", "==", "attended"), where("uid", "==", studentUid));


    const [pending] = useCollectionData(pendingQ, { idField: 'id' });
    const [confirmed] = useCollectionData(confirmedQ, { idField: 'id' });
    const [attended] = useCollectionData(attendedQ, { idField: 'id' });
    //grab the one document that matches the query
    //set your init states as false so we can make a loader
    const arrayOfPointer = [{
                            name: "left", per: "5.5%"}, 
                            {name: "left", per: "25%"},
                            {name: "left", per: "47.5%"},
                            {name: "right", per: "24%"},
                            {name: "right", per: "3.5%"}]

    const triggerProgressText = (num) => {
        setPointer(num);
    }


    useEffect(() => {
        userCheck(user.uid, user.email).then(
                    (result) => {
                        setStudentUid(result.uid);
                        setProgressLevel(result.level);
                        setTutors(result.tutors);
                        setTotalSessions(result.totalSessions);
                        setAscendScore(result.ascendScore);
                        setPointer(result.level)
                        setUserType(result.userType);
                        setFirstName(result.firstName);

                        const avatar =  createAvatar(style, {
                              size: 128,
                              seed: result.firstName,
                              eyes: ["bulging","hearts","eva", "robocop", "roundFrame01", "roundFrame02", "happy", "frame1"]
                              // ... other options
                            }).toDataUriSync();
                        setAvatar(avatar);
                        setLoad(false);
                        return result;
                    }

                    
        );
        }, [])


    return (
        <div className="platform-right-container">
                <PlatformHeader 
                    handleAlerts={props.triggerAlertBox} 
                    tab="Progress"
                    alertCount={alertNum}></PlatformHeader>
            {
                (!load) ? (
                    <>          
                        <div>
                        </div>
                        <div className="progress-scroll-container">
                            <div className="progress-intro-box">
                                <span>
                                    <h1>Welcome {firstName}!</h1>
                                    <img src={avatar}></img>
                                </span>
                                
                                {
                                    /*

                                    <section>
                                        <div>
                                            <h5>{tutors}</h5>
                                            <p>Tutors</p>
                                        </div>
                                        <div>
                                            <h5>{totalSessions}</h5>
                                            <p>Total sessions</p>
                                        </div>
                                        <div>
                                            <h5>{ascendScore}</h5>
                                            <p>Ascend Score</p>
                                        </div>
                                    </section>
                                */
                                }
                                
                            
                            </div>
                            <div className="progress-session-container-title">
                                <h3>Upcoming Sessions</h3>
                                <button onClick={() => props.redirect('sessions')}>Go to Sessions</button>
                            </div>
                            
                            <div className="progress-session-container">
                                
                                { confirmed && (confirmed.length === 0) && (
                                    <span>No Upcoming Sessions. 
                                        <button onClick={() => props.redirect("schedule")}>Start Scheduling</button>
                                    </span>
                                )}

                                {
                                    !confirmed && (
                                        <span>Loading...</span>
                                    )
                                }

                                {   confirmed &&
                                    
                                    //map first 3
                                    confirmed.slice(0, 3).map((item, index) => {
                                        return (
                                            <Event 
                                                handleReschedule={(item) => props.rescheduleRedirect(item)} 
                                                type={item.type} 
                                                eventData={item} 
                                                key={index}
                                                userType = {userType}
                                            ></Event>
                                        )
                                    })
                                }
                                
                                {/*

                                <Event type="student-requested"></Event>
                                <Event type="tutor-requested"></Event>
                                <Event type="session-confirmed"></Event>
                                */}
                                
                                
                            </div>
                            
                            <div className="progress-session-container-title">
                                <h3>Pending Sessions</h3>
                                <button onClick={() => props.redirect('sessions')}>Go to Sessions</button>
                            </div>

                            <div className="progress-session-container">
                            { 
                            pending && (pending.length === 0) && (
                                    <span>No Pending Sessions. 
                                        <button onClick={() => props.redirect("schedule")}>Start Scheduling</button>
                                    </span>
                                )}
                            {
                            pending && (
                                        pending.slice(0, 3).map((item, index) => {
                                            return (
                                                <Event
                                                    handleReschedule={(item) => props.rescheduleRedirect(item)}
                                                    type={item.type}
                                                    eventData={item}
                                                    key={index}
                                                    userType = {userType}
                                                ></Event>
                                            )
                                            })
                                    )
                            }
                            </div>
                            
                            <div className="progress-session-container-title">
                            <h3>Previous Sessions</h3>
                            </div>

                            <div className="progress-session-container">
                                {attended && (attended.length == 0) && (
                                    <span>No Previous Sessions.</span>
                                    )}


                                {attended && attended.slice(0, 3).map((item, index) => {
                                    return (
                                        <Event
                                            type={item.type}
                                            eventData={item}
                                            key={index}
                                            userType = {userType}
                                        ></Event>
                                    )
                                })}
                            </div>

                                

                            <h3>Progress</h3>
                            <div className="progress-container">
                                <div className="progress-bar">
                                    <div className={"progress-focus " + "progress-"+progressLevel}></div>

                                    <div className="progress-item" onClick={() => triggerProgressText(1)}>
                                        <FontAwesomeIcon icon={faCalendarDays}/>
                                    </div>

                                    <div className="progress-item" onClick={() => triggerProgressText(2)}>
                                        <FontAwesomeIcon icon={faChalkboardUser}/>
                                    </div>
                                    <div className="progress-item" onClick={() => triggerProgressText(3)}>
                                        <FontAwesomeIcon icon={faChartLine}/>

                                    </div>
                                    <div className="progress-item" onClick={() => triggerProgressText(4)}>
                                        <FontAwesomeIcon icon={faRocket}/>

                                    </div>
                                    <div className="progress-item" onClick={() => triggerProgressText(0)}>
                                        <FontAwesomeIcon icon={faHeadset}/>
                                    </div>

                                </div>
                            </div>

                            <div className="progress-text">
                                {arrayOfPointer[pointer].name === "left" ?
                                    <div className="top-pointer" style={{left:arrayOfPointer[pointer].per}}>
                                    </div>
                                    :
                                    <div className="top-pointer" style={{right:arrayOfPointer[pointer].per}}>
                                    </div>
                                }

                                <div className="progress-text-container">
                                    {pointer === 0 &&
                                        <section>
                                            <h2>Sign Up for a free consultation!</h2>
                                            <p>Meet with a mentor to discuss your goals and get advice on how to achieve them.
                                                It's important to us that we fully understand the student's needs and goals before
                                                we begin scheduling classes. This is also a great opportunity for the student to
                                                ask any questions they may have about Ascend Academy!
                                            </p>
                                            <button
                                                onClick={() => props.redirect("schedule")} 
                                                ><FontAwesomeIcon icon={faHeadset}/>Schedule a Chat
                                            </button>
                                            
                                        </section>
                                        
                                    }  

                                    {pointer === 1 && 
                                        <section>
                                            <h2>Set Up Your Payment</h2>
                                            <p>
                                                After your consultation, set up your payment option through our secure portal. You will be billed monthly for the completed sessions. A itemized summary 
                                                of your sessions and payment will be sent to you at the end of each month.
                                            </p>
                                            <button
                                                onClick={() => props.redirect("payment")} icon={faCreditCard}
                                                ><FontAwesomeIcon/>Set Up Payment
                                            </button>
                                            
                                        </section>
                                    }   
                                    {pointer === 2  &&
                                        <section>
                                            <h2>Meet Your Mentor.</h2>
                                            <p>If we've done our part, you should be matched with a personalized mentor. Feel free to explore other subjects that we offer and fill your schedule with personalized learning!
                                            </p>
                                            <button 
                                                onClick={() => props.redirect("schedule")}
                                                ><FontAwesomeIcon icon={faChalkboardUser}/>Chat
                                            </button>
                                            
                                        </section>
                                    }   
                                    {pointer === 3 &&
                                        <section>
                                            <h2>Track Your Progress.</h2>
                                            <p>Recieve weekly and monthly reports on your progress. We will also send you updates on your schedule and any changes that may occur.
                                                Not meeting your goals? No problem! We will work with you to find a solution, whether it be rescheduling or changing mentors.
                                            </p>
                                            <button
                                                onClick={() => props.redirect("profile")}
                                                ><FontAwesomeIcon icon={faChartLine}/>Progress
                                            </button>
                                            
                                        </section>
                                    }   
                                    {pointer === 4 &&
                                        <section>
                                            <h2>Start to Ascend!</h2>
                                            <p>After you've ascended through your classes, passions, goals, and more, start to explore where else you can go. We will help you find the right path for you!
                                                Recieve personalized mentorship for college applications, career advice, and more from our team of industry experts.
                                            </p>
                                            <button
                                                onClick={() => props.redirect("schedule")} icon={faRocket}
                                                ><FontAwesomeIcon/>Ascend!
                                            </button>
                                            
                                        </section>
                                    }   
                                </div>

                                    
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <Loader></Loader>
                )
            }


            
        </div>
    );
    }

export default StudentProgress;