import './DescriptionBox.css'
import TitleBox from './TitleBox'


function DescriptionBox(props) {
    return (
        <div className='description-box-container'>
        <TitleBox></TitleBox>
        <div className="description-box">
            <p>Our mission is to help students elevate their academic performance, extracurriculars, and interests to their full potential. Get matched with one of our hand-picked mentors and create a learning path that fits your schedule.
            </p>
            <div className="description-button-box">
                <button onClick={props.handleClick}>Get Started!</button>
            </div>
        </div>
        </div>)
}

export default DescriptionBox