import './Footer.css';


function Footer() {
    return (
        <div className="footer">
            <span>“Tell me and I forget, teach me and I may remember, involve me and I learn.” - Benjamin Franklin</span>
            <p>©2022 ASCEND ACADEMY, INC. MADE IN BOSTON, MASSACHUSETTS. ALL RIGHTS RESERVED.</p>
        </div>
    )
}

export default Footer