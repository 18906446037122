//export const site = "https://18.222.173.163"

//prod
export const site = "https://ascendacademyapi.com"
export const port = ""; // :5000

//dev
//export const site = "http://localhost";
//export const port = ":3001";


//ascend-academy-api-1315934572.us-east-2.elb.amazonaws.com