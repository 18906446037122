import './Header.css'
import Logo from './../img/ascend_logo.png'
import googleIcon from '../img/googleicon.png'

function Header(props) {

    return (
        <div className="header">
            <h1>Ascend Academy</h1>
            <div className="header-tab-box">
                <h3 onClick={props.mission}>Mission</h3>
                <h3 onClick={props.tutors}>Tutors</h3>
                <h3 onClick={props.reviews}>Reviews</h3>
                <h3 onClick={props.pricing}>Pricing</h3>
                <button className="purple" onClick={props.onSignUp}>
                    <img src={googleIcon}></img>
                    Sign Up</button>
                <button onClick={props.onSignIn}>
                    <img src={googleIcon}></img>
                Login</button>
            </div>
        </div>
    )
}

export default Header