import './Sessions.css';
import PlatformHeader from '../PlatformHeader';
import { auth, db, getUser, userCheck} from '../../../Firebase';
import { useState, useEffect } from 'react';
import { site, port } from '../../EnvVariables';
import Event from '../Event';
import Loader from '../Loader';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {query, collection, where, orderBy } from "firebase/firestore"; 
import { useCollectionData } from 'react-firebase-hooks/firestore';


function StudentSessions(props){

    const user = auth.currentUser;
    const [studentUid, setStudentUid] = useState(user.uid);
    const [confirmed, setConfirmed] = useState(false);
    const [updates, setUpdates] = useState(false);
    const [pending, setPending] = useState(false);
    const [attended, setAttended] = useState(false);
    const [all, setAll] = useState(true);
    //const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState(false);

    const changeTab = (e, tab) => {
        setLoading(false);
        const parent = e.target.parentElement;
        const children = parent.children;
        for (let i = 0; i < children.length; i++) {
            children[i].classList.remove("sessions-header-active");
        }
        e.target.classList.add("sessions-header-active");
        switch (tab) {
            case 'all':
                setAll(true);
                setConfirmed(false);
                setUpdates(false);
                setPending(false);
                setAttended(false);
                break;
            case 'confirmed':
                setConfirmed(true);
                setUpdates(false);
                setPending(false);
                setAll(false);
                setAttended(false);
                break;
            case 'updates':
                setConfirmed(false);
                setUpdates(true);
                setPending(false);
                setAll(false);
                setAttended(false);
                break;
            case 'requests':
                setConfirmed(false);
                setUpdates(false);
                setPending(true);
                setAll(false);
                setAttended(false);
                break;
            case 'attended':
                setConfirmed(false);
                setUpdates(false);
                setPending(false);
                setAll(false);
                setAttended(true);
            default:
                break;
        }
    }
    const events = collection(db, "events");
    const pendingQ = query(events, where("status", "==", "pending"), where("uid", "==", studentUid));
    const confirmedQ = query(events, where("status", "==", "confirmed"), where("uid", "==", studentUid));
    const updatedQ = query(events, where("status", "==", "updated"), where("uid", "==", studentUid));
    const attendedQ = query(events, where("status", "==", "attended"), where("uid", "==", studentUid));
    const eventsQ = query(events, where("uid", "==", studentUid));

    const [pendingData] = useCollectionData(pendingQ, { idField: 'id' });
    const [confirmedData] = useCollectionData(confirmedQ, { idField: 'id' });
    const [updatesData] = useCollectionData(updatedQ, { idField: 'id' });
    const [attendedData] = useCollectionData(attendedQ, { idField: 'id' });
    const [allData] = useCollectionData(eventsQ, { idField: 'id' });
    
    useEffect(() => {
        setLoading(true);
        userCheck(user.uid, user.email).then((result) => {
            setStudentUid(result.uid);
            setUserType(result.userType);
            setLoading(false);
        })
    }, [])
        
    return (
        <div className="platform-right-container">
            <PlatformHeader handleAlerts={props.triggerAlertBox} tab="Sessions"></PlatformHeader>
            <div className="sessions-container">
                <div className="sessions-container-header">
                <button onClick={(e) => changeTab(e,"all")} className="sessions-header-active">All</button>
                    <button onClick={(e) => changeTab(e,"confirmed")}>Confirmed</button>
                    <button onClick={(e) => changeTab(e,"updates")}>Updates</button>
                    <button onClick={(e) => changeTab(e,"requests")}>Requests</button>
                    <button onClick={(e) => changeTab(e,"attended")}>Attended</button>
                </div>

                {
                    loading ? (
                        <div className="sessions-loader">
                            <Loader></Loader>
                        </div>
                    ) : (
                        <div className="sessions">
                        {
                            all && allData &&(
                                <>
                                    {
                                    allData.length === 0 ?
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            allData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={userType} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                        handleReschedule={(item) => props.rescheduleRedirect(item)} 
                                                    ></Event>
                                                )}
                                    
                                            )}
                                        </>
                                    )
                                    }
                                </>
                            )
                        }
                        {
                            confirmed && confirmedData && (
                                <>
                                    {
                                    confirmedData.length === 0 ? 
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            confirmedData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={userType} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                        handleReschedule={(item) => props.rescheduleRedirect(item)} 

                                                    ></Event>
                                                )}
                                        
                                            )}
                                        </>
                                    )
                                    }
                                </>
                            )
                        }
    
                        {
                            updates && updatesData && (
                                <>
                                    {   updatesData.length === 0 ?
                                    (
                                        <h1>No Updates</h1>
                                    )
                                    :
                                    (
                                        <>
    
                                            {
                                            updatesData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={userType} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                        handleReschedule={(item) => props.rescheduleRedirect(item)} 
                                                    ></Event>
                                                )}
                                            )}
                                </>
                                    )}
                                </>
                            )
                        }
    
                        {
                            pending && pendingData && 
                            <>
                                {
                                    pendingData.length === 0 ?
                                    (
                                        <h1>No Requests</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            pendingData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        eventData={event} 
                                                        userType={userType} 
                                                        type={event.type} 
                                                        handleReschedule={(item) => props.rescheduleRedirect(item)} 
                                                    ></Event>
                                                )}
                                            )}
                                        </>
                                    )}
                            </>
                        }
                        
                        {                      
                            attended && attendedData && (
                                <>
                                    {
                                    attendedData.length === 0 ?
                                    (
                                        <h1>No Sessions</h1>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                            attendedData.map((event, index) => {
                                                return (
                                                    <Event 
                                                        key={index} 
                                                        userType={userType} 
                                                        eventData={event} 
                                                        type={event.type} 
                                                        handleReschedule={(item) => props.rescheduleRedirect(item)} 
                                                    ></Event>
                                                )}
                                            )}
                                        </>
                                    )}
                                </>
                            )

                    
                        }
                            
    
                    </div>
                    )
                }

            </div>
        </div>
    )
}

export default StudentSessions;